import { ActionTree } from 'vuex'
import { State } from './_state'
import { RootState } from '@/store'
import {
  notificationGroupData,
  getNotificationGroups,
} from '@/components/notifications-list/notifications'
import { MutationNames } from './_mutations'

export enum ActionNames {
  RETRIEVE_NOTIFICATIONS = 'RETRIEVE_NOTIFICATIONS',
}

export const actions: ActionTree<State, RootState> = {
  /**
   * Retrieves notification groups, processes each group's notifications,
   * and commits the non-empty groups to the store.
   *
   * @param {Object} context - The Vuex context object.
   * @returns {Promise<void>} A promise that resolves once the data has been fetched and committed.
   */
  async [ActionNames.RETRIEVE_NOTIFICATIONS]({ commit }): Promise<void> {
    const groups = getNotificationGroups()

    // Process each group to gather notifications
    const data: notificationGroupData[] = await Promise.all(
      groups.map(async (group) => {
        const notifications = await group.retrieveNotifications()
        return {
          label: group.label,
          anchor: group.anchor,
          counter: notifications.length,
          notifications,
        }
      }),
    )
    // Filter out empty notification groups and commit to state
    const nonEmptyGroups = data.filter(
      (group) => group.notifications.length > 0,
    )
    commit(MutationNames.SET_NOTIFICATION, nonEmptyGroups)
  },
}
