import { systemApi } from '@/addons/axios'
import { GetMenuAttributesSubmenu, GetMenuData } from '@/api'
import { menu } from '@/configs'
import { Routes } from '@/router'
import { RootState } from '@/store'
import { ActionContext } from 'vuex'

type MenuActionContext = ActionContext<MenuState, RootState>

export interface MenuState {
  items: GetMenuData[]
  currentTabIndex: number
}

enum Actions {
  UPDATE = 'update',
  RESET_STATE = 'reset-state',
  SET_CURRENT_TAB = 'set-current-tab',
}

enum Mutations {
  ITEMS = 'setItems',
  RESET_STATE = 'reset-state',
  SET_CURRENT_TAB = 'set-current-tab',
}

enum Getters {
  GET_ITEMS = 'getItems',
  GET_FLATTENED_ITEMS = 'getFlattenedItems',
  GET_FLATTENED_ITEMS_WITH_CONTEXT = 'getFlattenedItemsWithContext',
  GET_SHORTCUTS = 'getShortcuts',
  HAS_ITEM_WITH_PATH = 'hasItemWithPath',
  GET_CURRENT_TAB = 'get-current-tab',
  GET_ALL_ITEMS = 'get-all-items',
}

export const MenuActions = {
  UPDATE: `menu/${Actions.UPDATE}`,
  RESET_STATE: `menu/${Actions.RESET_STATE}`,
  SET_CURRENT_TAB: `menu/${Actions.SET_CURRENT_TAB}`,
}

export const MenuMutations = {
  ITEMS: `menu/${Mutations.ITEMS}`,
  RESET_STATE: `menu/${Mutations.RESET_STATE}`,
  SET_CURRENT_TAB: `menu/${Mutations.SET_CURRENT_TAB}`,
}

export const MenuGetters = {
  GET_ITEMS: `menu/${Getters.GET_ITEMS}`,
  GET_FLATTENED_ITEMS: `menu/${Getters.GET_FLATTENED_ITEMS}`,
  GET_FLATTENED_ITEMS_WITH_CONTEXT: `menu/${Getters.GET_FLATTENED_ITEMS_WITH_CONTEXT}`,
  GET_SHORTCUTS: `menu/${Getters.GET_SHORTCUTS}`,
  HAS_ITEM_WITH_PATH: `menu/${Getters.HAS_ITEM_WITH_PATH}`,
  GET_CURRENT_TAB: `menu/${Getters.GET_CURRENT_TAB}`,
  GET_ALL_ITEMS: `menu/${Getters.GET_ALL_ITEMS}`,
}

const initState = (): MenuState => ({
  items: [],
  currentTabIndex: 0,
})

export default {
  namespaced: true,

  state: initState,

  actions: {
    async [Actions.UPDATE]({ commit }: MenuActionContext): Promise<void> {
      const menuApiResponse = await systemApi.apiV1PoswebMenusGet()
      const menu_items = menuApiResponse.data.data
      // console.log(menu_items)

      // TODO: COMMENTARE ASAP! -> SOLO PER TEST e MOCK <-
      // <- Start MOCK menu item -->
      // const mainArea = 'POS:::dashboard_frontoffice' // 'POS:::dashboard_backoffice'
      // const mainSubMenuIdx = 1 // 0
      // menu_items
      //   .find((m) => m.id === mainArea)
      //   ?.attributes?.submenu?.[mainSubMenuIdx]?.attributes?.submenu?.push({
      //     id: 'POS:::PromoEngine',
      //     type: 'link',
      //     attributes: {
      //       link: '/promo-engine',
      //       label:
      //         '<trad group="mmfg_menu" id="promo-engine">Promo Engine</trad>',
      //       submenu: [],
      //       qs: '',
      //     },
      //   }) ?? [] //
      // <- End MOCK menu item -->
      commit(Mutations.ITEMS, menu_items)
    },
    [Actions.RESET_STATE]({ commit }: MenuActionContext): void {
      commit(Mutations.RESET_STATE)
    },
    [Actions.SET_CURRENT_TAB]: (
      context: MenuActionContext,
      tab: string,
    ): void => {
      context.commit(Mutations.SET_CURRENT_TAB, tab)
    },
  },

  mutations: {
    [Mutations.ITEMS](state: MenuState, items: Array<GetMenuData>) {
      state.items = items
    },
    [Mutations.RESET_STATE](state: MenuState) {
      Object.assign(state, initState())
    },
    [Mutations.SET_CURRENT_TAB](state: MenuState, tab: number) {
      state.currentTabIndex = tab
    },
  },

  getters: {
    /**
     * Returns menu tree structure
     */
    [Getters.GET_ITEMS]: (state: MenuState) => {
      const sorted: GetMenuAttributesSubmenu[] = []

      if (state.items.length) {
        menu.topLevelOrder.forEach((id) => {
          const menuItem = state.items.filter((i) => i.id === id).shift()
          if (menuItem) {
            sorted.push(menuItem)
          }
        })
      }

      return sorted
    },

    /** Returns the tree menu structure, recursively flattened to a list of menu items */
    [Getters.GET_FLATTENED_ITEMS]: (
      _state: MenuState,
      getters: Record<Getters, unknown>,
    ) => {
      const items = getters[Getters.GET_ITEMS] as GetMenuAttributesSubmenu[]
      return flattenItems(items)
    },

    /**
     * Returns the tree menu structure, recursively flattened to a list of menu items, grouped by page context.
     * Page context is used as a key.
     */
    [Getters.GET_FLATTENED_ITEMS_WITH_CONTEXT]: (
      _state: MenuState,
      getters: Record<Getters, unknown>,
    ): Record<string, GetMenuAttributesSubmenu[]> => {
      const items = getters[Getters.GET_ITEMS] as GetMenuAttributesSubmenu[]
      const result = {} as Record<string, GetMenuAttributesSubmenu[]>

      for (const item of items) {
        if (item.attributes?.submenu && item.attributes.link) {
          const contextString = item.attributes.link.split('.')[1]

          result[contextString] = flattenItems(item.attributes.submenu)
        }
      }

      return result
    },

    /**
     * Given a specific item, this getter returns a list of shortcuts.
     */
    [Getters.GET_SHORTCUTS]: () => (section: GetMenuAttributesSubmenu) => {
      if (section?.attributes?.submenu) {
        const child = flattenItems(section.attributes?.submenu || [])
        return child.filter((c) => menu.shortcutIds.includes(c.id || ''))
      }
      return []
    },

    /** Returns whether current menu includes an item that matches the received path */
    [Getters.HAS_ITEM_WITH_PATH]:
      (_state: MenuState, getters: Record<string, unknown>) =>
      (path: string) => {
        const menuItems = getters[
          Getters.GET_FLATTENED_ITEMS
        ] as GetMenuAttributesSubmenu[]

        // Check whether the received path is from a well-formed and an iframe-based page
        if (
          path.startsWith(Routes.PREVIOUS_UI_PAGES) &&
          path.includes('module') &&
          path.includes('program')
        ) {
          const params = new URL(`${origin}${path}`).searchParams
          const m = params.get('module')
          const p = params.get('program')

          // If so, we have to check whether we have a menu page with that tuple of module and program
          return menuItems.some(
            (item) => item.attributes?.qs === `?module=${m}&program=${p}`,
          )
        }

        // Otherwise we have to match on the link attribute.
        return menuItems.some((item) => item.attributes?.link === path)
      },
    [Getters.GET_CURRENT_TAB]: (state: MenuState): number =>
      state.currentTabIndex,
    [Getters.GET_ALL_ITEMS]: (state: MenuState): GetMenuData[] => state.items,
  },
}

function flattenItems(
  section: GetMenuAttributesSubmenu[],
): GetMenuAttributesSubmenu[] {
  if (!section.length) {
    return []
  }

  return section.flatMap((items) =>
    items.attributes?.submenu?.length
      ? flattenItems(items.attributes.submenu)
      : items,
  )
}
