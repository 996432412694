import { ActionContext, useStore } from 'vuex'
import { RootState } from '@/store/index'
import { dashboardApi } from '@/addons/axios'
import {
  ResponseDashboardStatoNegozioDataAttributes,
  ResponsePayload9DataAttributesDashboardPromemoria,
  ResponsePayload9DataAttributesDashboardElements,
  ResponsePayload9DataAttributes,
} from '@/api'
import { DataTypeReq, StoreStatus as StoreStatusEnum } from '@/addons/enums'
import i18n from '@/addons/i18n'
import { extractErrorMessage } from '@/addons/functions'
import { NotificationsActions } from '@/store/notifications-store'
import { ErrorD } from '@/addons/interface'
import { addNotificationGroup } from '@/components/notifications-list/notifications'
export interface StoreStatusState {
  storeStatus: ResponseDashboardStatoNegozioDataAttributes[]
  reminders: ResponsePayload9DataAttributesDashboardPromemoria[]
  dashboard_elements: ResponsePayload9DataAttributesDashboardElements | null
  cec: ResponsePayload9DataAttributesDashboardPromemoria[]
  b2c: ResponsePayload9DataAttributesDashboardPromemoria[]
}
export enum Actions {
  SET_STORE_STATUS_CASH = 'get-store-status-cash',
  SET_STORE_STATUS = 'get-store-status',
  SET_REMINDERS = 'get-promemoria',
  SET_CEC = 'get-cec',
  SET_B2C = 'get-b2c',
}
export enum Mutations {
  STORE_STORE_STATUS = 'store-store-status',
  STORE_DASHBOARD_ELEMENTS = 'store-dashboard-elements',
  STORE_REMINDERS = 'store-reminders',
  STORE_CEC = 'store-cec',
  STORE_B2C = 'store-b2c',
}
export enum Getters {
  GET_STORE_BOOLEAN_STATUS = 'get-store-boolean-status',
  GET_STORE_STATUSES = 'get-store-statuses',
  GET_REMINDERS = 'get-promemoria',
  GET_CEC = 'get-cec',
  GET_REPORTB2C = 'get-b2c',
  GET_DASHBOARD_ELEMENTS = 'get-dashboard-elements',
}

const initState = (): StoreStatusState => ({
  storeStatus: [],
  reminders: [],
  dashboard_elements: null,
  cec: [],
  b2c: [],
})
const storeStatusStore = {
  namespaced: true,
  state: initState(),
  actions: {
    [Actions.SET_STORE_STATUS]: async (
      context: ActionContext<StoreStatusState, RootState>,
    ) => {
      const store = useStore()
      try {
        const status = (await dashboardApi.apiV1DashboardStatoNegozioGet()).data
          .data?.attributes
        status?.map((s) => {
          const dt = <DataTypeReq | undefined>s?.data_type
          if (dt === DataTypeReq.ASYNC) {
            s.status = StoreStatusEnum.LOADING
          }
          return s
        })
        context.commit(Mutations.STORE_STORE_STATUS, status)
        await context.dispatch(Actions.SET_STORE_STATUS_CASH)
      } catch (err: unknown) {
        const error = err as ErrorD
        let errorMessage = i18n.global.t('pos_common.generic_error')

        if (error?.response?.data?.errors?.length) {
          const details = extractErrorMessage(
            error?.response?.data?.errors[0].detail || '',
          )
          if (details) {
            errorMessage += `:\n${details}`
          }
        }

        await store.dispatch(NotificationsActions.NOTIFY_ERROR, errorMessage, {
          root: true,
        })
      }
    },
    [Actions.SET_STORE_STATUS_CASH]: async (
      context: ActionContext<StoreStatusState, RootState>,
    ) => {
      const status = context?.state?.storeStatus
      try {
        const statusCash =
          (await dashboardApi.apiV1DashboardStatoNegozioRegistratoreCassaGet())
            .data.data?.attributes || {}
        if (statusCash) {
          const findEl =
            status?.find(
              (e: ResponsePayload9DataAttributes) =>
                e.key === (statusCash as ResponsePayload9DataAttributes)?.key,
            ) || {}
          const index = status?.indexOf(findEl)
          if (status[index] && index !== -1) {
            status[index] = Object.assign({}, status[index], statusCash)
            context.commit(Mutations.STORE_STORE_STATUS, status)
          }
        }
      } catch {
        status?.map((s) => {
          if (s.status === StoreStatusEnum.LOADING) {
            s.status = StoreStatusEnum.ERROR
          }
          return s
        })
        context.commit(Mutations.STORE_STORE_STATUS, status)
      }
    },
    [Actions.SET_REMINDERS]: async (
      context: ActionContext<StoreStatusState, RootState>,
    ) => {
      try {
        const response =
          await dashboardApi.apiV1DashboardPromemoriaElementoGet('promemoria')
        let prom = response.data.data?.attributes?.dashboard_promemoria || []

        /** 'info_bolle' */
        if (response.data.data?.attributes?.dashboard_elements?.info_bolle) {
          const responseInfoBolle =
            await dashboardApi.apiV1DashboardPromemoriaElementoGet('info_bolle')
          prom = [
            ...prom,
            ...(responseInfoBolle.data.data?.attributes?.dashboard_promemoria ||
              []),
          ]
        }
        /** 'info_vendite_non_accolte' */
        if (
          response.data.data?.attributes?.dashboard_elements
            ?.info_vendite_non_accolte
        ) {
          const responseInfoVenditeNonAccolte =
            await dashboardApi.apiV1DashboardPromemoriaElementoGet(
              'info_vendite_non_accolte',
            )
          prom = [
            ...prom,
            ...(responseInfoVenditeNonAccolte.data.data?.attributes
              ?.dashboard_promemoria || []),
          ]
        }
        /** 'info_shortage' */
        if (response.data.data?.attributes?.dashboard_elements?.info_shortage) {
          const responseInfoShortage =
            await dashboardApi.apiV1DashboardPromemoriaElementoGet(
              'info_shortage',
            )
          prom = [
            ...prom,
            ...(responseInfoShortage.data.data?.attributes
              ?.dashboard_promemoria || []),
          ]
        }

        /**
         * Stores the reminders from the `prom` array into the `state.reminders`
         */
        if (prom.length) {
          context.commit(Mutations.STORE_REMINDERS, prom)

          // Adding notification group for reminders
          addNotificationGroup({
            weight: 100,
            enabled: () => true,
            label: 'pos_dashboard.automatic_memos',
            anchor: 'reminders',
            retrieveNotifications: () => {
              return Promise.resolve(
                prom.map((p) => ({
                  messaggio: p.messaggio as string,
                  action: true,
                  id_menu: p.id_menu,
                })),
              )
            },
          })
        }

        if (response.data.data?.attributes?.dashboard_elements) {
          context.commit(
            Mutations.STORE_DASHBOARD_ELEMENTS,
            response.data.data?.attributes?.dashboard_elements,
          )
        }
      } catch (e: unknown) {
        const r = e as ErrorD
        const msg = r?.response?.data?.errors?.length
          ? extractErrorMessage(r?.response?.data?.errors[0].detail ?? '')
          : 'pos_common.generic_error'
        context.dispatch(NotificationsActions.NOTIFY_ERROR, msg, { root: true })
      }
    },
    [Actions.SET_CEC]: async (
      context: ActionContext<StoreStatusState, RootState>,
    ) => {
      try {
        const response =
          await dashboardApi.apiV1DashboardPromemoriaElementoGet('cec')
        const cec = response.data.data?.attributes?.dashboard_promemoria
        context.commit(Mutations.STORE_CEC, cec)

        // Adding notification group for CEC
        if (cec) {
          addNotificationGroup({
            weight: 300,
            enabled: () => true,
            label: 'pos_dashboard.b2e_report',
            anchor: 'cec',
            retrieveNotifications: () => {
              return Promise.resolve(
                cec.map((c) => ({
                  messaggio: c.messaggio as string,
                  action: true,
                  id_menu: c.id_menu,
                })),
              )
            },
          })
        }

        dashboardApi.apiV1DashboardPromemoriaElementoGet('cec').then((r) => {
          context.commit(
            Mutations.STORE_CEC,
            r.data.data?.attributes?.dashboard_promemoria,
          )
        })
      } catch (e: unknown) {
        const r = e as ErrorD
        const msg = r?.response?.data?.errors?.length
          ? extractErrorMessage(r?.response?.data?.errors[0].detail ?? '')
          : 'pos_common.generic_error'
        context.dispatch(NotificationsActions.NOTIFY_ERROR, msg, { root: true })
      }
    },
    [Actions.SET_B2C]: async (
      context: ActionContext<StoreStatusState, RootState>,
    ) => {
      try {
        const response =
          await dashboardApi.apiV1DashboardPromemoriaElementoGet('b2c')
        const b2c = response.data.data?.attributes?.dashboard_promemoria
        context.commit(Mutations.STORE_B2C, b2c)
        // Adding notification group for B2C
        if (b2c) {
          addNotificationGroup({
            weight: 400,
            enabled: () => true,
            label: 'B2C',
            anchor: 'b2c',
            retrieveNotifications: () => {
              return Promise.resolve(
                b2c.map((b) => ({
                  messaggio: b.messaggio as string,
                  action: true,
                  id_menu: b.id_menu,
                })),
              )
            },
          })
        }
      } catch (e: unknown) {
        const r = e as ErrorD
        const msg = r?.response?.data?.errors?.length
          ? extractErrorMessage(r?.response?.data?.errors[0].detail ?? '')
          : 'pos_common.generic_error'
        context.dispatch(NotificationsActions.NOTIFY_ERROR, msg, { root: true })
      }
    },
  },
  mutations: {
    [Mutations.STORE_STORE_STATUS]: (
      state: StoreStatusState,
      storeStatus: ResponseDashboardStatoNegozioDataAttributes[],
    ) => {
      state.storeStatus = storeStatus
    },
    [Mutations.STORE_REMINDERS]: (
      state: StoreStatusState,
      reminders: ResponsePayload9DataAttributesDashboardPromemoria[],
    ) => {
      state.reminders = reminders
    },
    [Mutations.STORE_DASHBOARD_ELEMENTS]: (
      state: StoreStatusState,
      dashboard_elements: ResponsePayload9DataAttributesDashboardElements,
    ) => {
      state.dashboard_elements = dashboard_elements
    },
    [Mutations.STORE_CEC]: (
      state: StoreStatusState,
      cec: ResponsePayload9DataAttributesDashboardPromemoria[],
    ) => {
      state.cec = cec
    },
    [Mutations.STORE_B2C]: (
      state: StoreStatusState,
      b2c: ResponsePayload9DataAttributesDashboardPromemoria[],
    ) => {
      state.b2c = b2c
    },
  },
  getters: {
    [Getters.GET_STORE_BOOLEAN_STATUS]: (state: StoreStatusState) => {
      return state.storeStatus.every(
        (s) => !!s.status || s.status === StoreStatusEnum.ERROR,
      )
    },
    [Getters.GET_STORE_STATUSES]: (
      state: StoreStatusState,
    ): ResponseDashboardStatoNegozioDataAttributes[] => state.storeStatus,
    [Getters.GET_DASHBOARD_ELEMENTS]: (
      state: StoreStatusState,
    ): ResponsePayload9DataAttributesDashboardElements | null =>
      state.dashboard_elements,
  },
}

export const StoreStatusActions = {
  SET_STORE_STATUS: `storeStatus/${Actions.SET_STORE_STATUS}`,
  SET_REMINDERS: `storeStatus/${Actions.SET_REMINDERS}`,
  SET_CEC: `storeStatus/${Actions.SET_CEC}`,
  SET_B2C: `storeStatus/${Actions.SET_B2C}`,
}

export const StoreStatusGetters = {
  GET_STORE_BOOLEAN_STATUS: `storeStatus/${Getters.GET_STORE_BOOLEAN_STATUS}`,
  GET_STORE_STATUSES: `storeStatus/${Getters.GET_STORE_STATUSES}`,
  GET_REMINDERS: `storeStatus/${Getters.GET_REMINDERS}`,
  GET_CEC: `storeStatus/${Getters.GET_CEC}`,
  GET_DASHBOARD_ELEMENTS: `storeStatus/${Getters.GET_DASHBOARD_ELEMENTS}`,
  GET_REPORTB2C: `storeStatus/${Getters.GET_REPORTB2C}`,
}

export default storeStatusStore
