import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'
import { App } from '@capacitor/app'
import { StatusBar, Style } from '@capacitor/status-bar'
import { KeyboardHelper, isNativeIos, isNativeMobileApp } from '@/addons/mobile'
import { ConnectivityChecker } from './addons/connectivity'
import { SoundCueHelper } from '@/addons/functions/sales'

/**
 * Initialize optional modules
 * @todo get modules from configs
 */

export function setupPlatformSpecific() {
  const setStatusBarStyle = async () => {
    // If we are actually running on an iOS device, change color status bar so that the text is darker.
    if (isNativeIos()) {
      // Yes, this is somewhat counter intuitive, but this is indeed correct.
      await StatusBar.setStyle({ style: Style.Light })
    }
  }

  if (isNativeMobileApp()) {
    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
      KeyboardHelper.keyboardDidShow(info)
    })

    // If user hides the keyboard when an input field is in focus, we can blur that field.
    // In this way, user can remove focus from an input field either by tapping away from it or by hiding the keyboard.
    Keyboard.addListener('keyboardWillHide', () => {
      KeyboardHelper.keyboardDidHide()
    })

    App.addListener('appStateChange', (state) => {
      // If application becomes active, we can restart the connectivity checks
      if (state.isActive) {
        ConnectivityChecker.getInstance().restart()
      } else {
        // Since we do not want users to be able to skim through the audio file, nor we want to give him/her the ability to
        // pause it and replay it, we set an empty src property so that the native Media controls are removed from the
        // lock screen (once the application has lost focus).
        SoundCueHelper.stop()
      }
    })
  }

  return { setStatusBarStyle }
}
