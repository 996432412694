/**
 * This code is the main entry point of the application. It performs the following tasks:
 * - Initializes the main application using `initializeApp()`
 * - Sets up the router using `setupRouter()`
 * - Configures platform-specific settings using `setupPlatformSpecific()`
 * - Parses SCSS variables from the `_mixins.scss` file and exports them as `SCSS_MIXINS`
 * - Imports global CSS styles for the application
 * - Mounts the application using the `mount()` function
 * - Exports the `app` instance for use in other parts of the application
 */

import { initializeApp } from './main-app-init'
import { setupRouter } from './router/main-router-guards'
import { setupPlatformSpecific } from './main-platform-specific'

// Style imports
import { parseScssVariabiles } from './addons/functions'
export const SCSS_MIXINS = parseScssVariabiles('@/styles/_mixins.scss')
import '@/theme/theme.scss'
import '@ionic/vue/css/core.css'
import 'vue-toastification/dist/index.css'

const { app, mount } = initializeApp()
setupRouter()

const { setStatusBarStyle } = setupPlatformSpecific()
setStatusBarStyle()

mount()

export { app }
