<script lang="ts" setup>
import { defineProps } from 'vue'
import FeButton from '@/components/global/button.vue'
import FeIcon from '@/components/global/icon.vue'

const props = defineProps({
  iconButton: {
    type: String,
    required: true,
    default: 'scan',
  },
  counter: {
    type: Number,
    required: true,
  },
  barcodes: {
    type: Object,
    required: true,
  },
})

const emits = defineEmits(['add-products'])
props
</script>

<template>
  <fe-button
    color="icon"
    class="button-confirm"
    data-test="button-card"
    @click="emits('add-products')"
  >
    <div class="button-container">
      <div v-if="counter > 0" class="counter-badge">{{ counter }}</div>
      <fe-icon :icon="iconButton"></fe-icon>
    </div>
  </fe-button>
</template>

<style lang="scss" scoped>
.button {
  &-container {
    position: relative;
    .counter-badge {
      position: absolute;
      top: -10px;
      right: -10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: var(--ion-color-primary);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      background-color: #4689f6;
      z-index: 100;
    }
  }
  &-confirm {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 64px;
    height: 64px;
    display: flex;
    z-index: 100;
    background-color: #c99483;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    .fe-icon {
      font-size: 24px;
      height: auto;
      width: auto;
      margin: 0 !important;
    }
    :deep(button) {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: transparent;
      color: white;
      padding: 0;
    }
  }
}
</style>
