<script lang="ts">
import { DefineComponent, defineComponent, nextTick, PropType } from 'vue'
import { IonInput, IonList } from '@ionic/vue'

type AutoFillType = 'on' | 'off'
export default defineComponent({
  name: 'FeSelect',

  components: {
    IonInput,
    IonList,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    selectedOptionLabel: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: undefined,
    },

    disabled: {
      type: Boolean,
      default: null,
    },

    parentClass: {
      type: String,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },

    hasAutocomplete: {
      type: Boolean,
      default: false,
    },

    keyboardNavigationEnabled: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: 'default',
    },

    footerContent: {
      type: String,
      default: null,
    },

    hasClearButton: {
      type: Boolean,
      default: false,
    },
    autoFillInput: {
      type: String as PropType<AutoFillType>,
      default: 'off',
    },
    removeLabelOnOpen: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'search-term-updated',
    'option-selected',
    'update:modelValue',
    'input-lost-focus',
    'on:focus',
    'on:blur',
  ],

  data() {
    return {
      inputValue: '',
      isInputFocused: false,
      currentlyFocused: 0,
      selectValue: false,
      onOpenFocus: this.isTablet(),
      childOptionsLength: 0,
    }
  },

  computed: {
    computedLabel() {
      if (!this.label) {
        return ''
      }
      return this.label + (this.mandatory ? '<sup>*</sup>' : '')
    },
    isDropdownVisible(): boolean {
      if (this.hasClearButton) {
        return this.isInputFocused && this.inputValue?.length > 0
      }
      return this.isInputFocused
    },

    isOptionSelected(): boolean {
      return true
    },

    /**
     * Determines whether the input value has an error border based on the error state and the length of the input value.
     *
     * @returns {boolean} `true` if the input value should have an error border, `false` otherwise.
     */
    hasErrorBorder(): boolean {
      return !!(this.error && (this.inputValue?.length ?? 0) <= 0)
    },
  },

  watch: {
    selectedOptionLabel(newValue: string) {
      this.inputValue = newValue
    },

    modelValue() {
      const elementIonInput = this.$refs.inputRef as DefineComponent | undefined
      this.updateFocusStatus(false)

      if (elementIonInput?.blur) {
        elementIonInput.blur()
      }
    },
  },

  mounted() {
    setTimeout(() => {
      if (!this.selectedOptionLabel) {
        return
      }
      this.inputValue = this.selectedOptionLabel
      this.setChildElementsNumber()
    }, 1)
    document.addEventListener('click', this.handleOutsideClick)
    document.addEventListener('keydown', this.handleKeydown)
  },

  unmounted() {
    this.onOpenFocus = false
    document.removeEventListener('click', this.handleOutsideClick)
    document.removeEventListener('keydown', this.handleKeydown)
  },

  methods: {
    setChildElementsNumber() {
      const optionItems = this.$el.querySelectorAll('ion-list > ion-item')
      this.childOptionsLength = optionItems.length || 0
    },
    isTablet() {
      return window.innerWidth > 1190 ? this.focused : false
    },

    /**
     * Handles the keydown event on the document. If the key pressed is the Tab key,
     * it sets the `onOpenFocus` flag to false and updates the focus status to false.
     *
     * @param event - The keyboard event object.
     */
    handleKeydown(event: any) {
      if (event.key === 'Tab') {
        this.onOpenFocus = false
        this.updateFocusStatus(false)
      }
    },

    /**
     * Handles keyboard up events on the select input.
     * - If the key pressed is not a navigation key (ArrowUp, ArrowDown, Enter, Tab), it sets the `selectValue` to `false`, resets the `currentlyFocused` index, emits a `search-term-updated` event with the current `inputValue`, and updates the focus status.
     * - It then triggers the `keyPress` event, which handles keyboard navigation.
     *
     * @param event - The keyboard event object.
     */
    handleKeyUp(event: KeyboardEvent) {
      // Only emit and update focus if it's not a navigation key
      const navKey = ['ArrowUp', 'ArrowDown', 'Enter', 'Tab']
      if (!navKey.includes(event.key)) {
        this.selectValue = false
        this.currentlyFocused = 0
        // this.$emit('search-term-updated', this.inputValue)
        this.updateFocusStatus(true)
      }
      // trigger old keyPressed events
      this.keyPress(event)
    },

    /**
     * Updates the focus status of the select input.
     *
     * If the input is not currently focused and the new focus status is also not focused, this method returns without doing anything.
     * If the input has autocomplete enabled, and the input value is not empty or the input is focused, this method emits an 'input-lost-focus' event with the current input value, and sets the input value to the selected option label.
     * Finally, this method sets the `selectValue` flag to false and resets the `currentlyFocused` index.
     *
     * @param isInputFocused - A boolean indicating whether the input is currently focused.
     */
    updateFocusStatus(isInputFocused: boolean): void {
      if (!this.isInputFocused && !isInputFocused) {
        return
      }
      this.isInputFocused = isInputFocused

      if (!this.hasAutocomplete || (!this.inputValue && !isInputFocused)) {
        return
      }

      if (!isInputFocused) {
        this.$emit('input-lost-focus', this.inputValue)
        this.inputValue = this.selectedOptionLabel || ''
      }
      this.selectValue = false
      this.currentlyFocused = 0
    },

    handleOutsideClick(event: Event): void {
      const isClickInsideSelect = this.$el.contains(event.target)
      if (isClickInsideSelect) {
        return
      }

      this.updateFocusStatus(false)
    },

    async handleOptionClick(
      selectedValue: string | number | boolean,
    ): Promise<void> {
      if (this.modelValue === selectedValue) {
        await nextTick()
      }
      this.$emit('update:modelValue', selectedValue)
      this.$emit('option-selected', selectedValue)

      await nextTick()

      this.updateFocusStatus(false)
    },

    /**
     * Handles keyboard navigation events for the select component.
     * This method is called when a keyboard event is triggered on the input field.
     * It allows the user to navigate through the available options using the arrow keys,
     * and select an option using the Enter key.
     *
     * @param event - The keyboard event object.
     */
    keyPress(event: KeyboardEvent) {
      this.setChildElementsNumber()
      if (this.keyboardNavigationEnabled && this.childOptionsLength > 0) {
        if (
          event.key === 'ArrowDown' &&
          this.currentlyFocused < this.childOptionsLength
        ) {
          this.currentlyFocused++
          // console.log('this.currentlyFocused: ' + this.currentlyFocused)
        } else if (event.key === 'ArrowUp' && this.currentlyFocused > 1) {
          this.currentlyFocused--
        } else if (event.key === 'Enter') {
          this.selectValue = true
        }
      }
    },

    clearInput() {
      this.inputValue = ''
      this.$emit('update:modelValue', '')
      this.$emit('option-selected', '')
    },
  },
})
</script>

<template>
  <div class="select abs-input-wrapper">
    <div
      class="field"
      :class="{
        'item-has-focus': isInputFocused,
        underlineBorderErrorRed: hasErrorBorder,
      }"
      :disabled="!!disabled"
    >
      <!-- ion-label v-if="label" :class="parentClass" position="floating">
        {{ label }}<sup v-if="mandatory">*</sup>
      </label> -->

      <ion-input
        ref="inputRef"
        v-model="inputValue"
        label-placement="floating"
        :debounce="300"
        :disabled="!!disabled"
        :autofocus="onOpenFocus"
        :autocomplete="autoFillInput"
        @ion-change="() => $emit('search-term-updated', inputValue)"
        @keyup="handleKeyUp"
        @ion-blur="
          () => {
            $emit('on:blur', inputValue)
          }
        "
        @ion-focus="
          () => {
            $emit('on:focus', inputValue)
            updateFocusStatus(true)
          }
        "
      >
        <!-- eslint-disable-next-line vue/no-v-html : altrimenti il tag `sup` non viene accettato -->
        <div v-if="label" slot="label" v-html="computedLabel"></div>
      </ion-input>

      <fe-icon
        v-show="hasAutocomplete && inputValue?.length > 0"
        class="icon icon_close"
        icon="close"
        @click="clearInput"
      />
      <fe-icon v-show="!hasAutocomplete" class="icon" icon="caret-down" />
      <span
        v-if="error && inputValue?.length <= 0"
        class="input-error-message"
        >{{ error }}</span
      >
    </div>

    <button
      v-if="!hasAutocomplete"
      type="button"
      class="overlay-button"
      :disabled="disabled"
      @click.prevent="updateFocusStatus(!isInputFocused)"
    />
    <ion-list
      v-if="isDropdownVisible"
      ref="test"
      class="options"
      :class="{ 'size-small': size === 'small' }"
      @change="handleOptionClick"
    >
      <slot />
      <div v-if="footerContent">
        <div class="options__footer">
          {{ footerContent }}
        </div>
      </div>
      <div v-else>
        <slot name="footer"></slot>
      </div>
    </ion-list>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';

@include tablet-landscape {
  .item-has-focus .label-floating.sc-ion-label-ios-h,
  .item-has-value .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 1rem) scale(0.8);
  }

  .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 3rem);
  }
}

@include ipadPro-landscape {
  .item-has-focus .label-floating.sc-ion-label-ios-h,
  .item-has-value .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 1.5rem) scale(0.8);
  }

  .label-floating.sc-ion-label-ios-h {
    transform: translate(0, 3rem);
  }
}

.select {
  position: relative;
  display: grid;
}

.field {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  &:deep(ion-input) {
    --padding-end: 2rem;
  }
}

.icon,
.overlay-button {
  @include z-index('header');
  position: absolute;
}

.overlay-button {
  top: 0;
  left: 0;
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.icon {
  right: 0.8rem;

  &:deep(ion-icon) {
    color: $color-black;
    font-size: 1rem;

    .item-has-focus & {
      color: $color-selected-light;
    }
  }
}

.icon_close {
  right: 0.8rem;
  bottom: 0.8rem;

  &:deep(ion-icon) {
    color: $color-base;
    font-size: 1.5rem;

    .item-has-focus & {
      color: $color-base;
    }
  }
}

.options {
  @include z-index('overlay', 2);
  position: absolute;
  left: 0;
  min-width: 100%;
  max-height: 40rem;
  //  top: 100%;
  // grid-row: 2 / 3;
  bottom: 0;
  transform: translateY(calc(100% + 0.1rem));
  border-radius: 0 0 0.8rem 0.8rem;
  box-shadow: 0 0.1rem 0.5rem rgba($color-black, 0.12);
  padding: 0;
  overflow: auto;

  .selected {
    background-color: $color-medium-grey;
  }

  &__footer {
    padding: 1.2rem 1.6rem 1.2rem 1.6rem;
    font-size: 1.4rem;
  }
}

.size-small {
  max-height: 20rem !important;
}
.underlineBorderErrorRed {
  --border-color: #f44336;
}
</style>
