import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue'
import feApp from '@/app.vue'
import router from '@/router'
import store from '@/store'
import Toast from 'vue-toastification'
import { createPinia } from 'pinia'
import i18n from '@/addons/i18n'
import { AbortControllerHelper, prepareApiHelpers } from '@/addons/axios'
import { initModules } from './modules/initModules'
import { FeLogger } from './addons/monitoring'
import Log from '@/addons/logger' // GBLogger

/**
 * Imports constants related to live update channels, including the default channel and a mapping of channels.
 * These constants are likely used to configure and manage live updates in the application.
 */
import {
  LIVE_UPDATE_CHANNEL_MAPPING,
  LIVE_UPDATE_DEFAULT_CHANNEL,
} from './configs'
import { ConfigActions, ConfigGetters } from './store/configs-store'
import { LiveUpdatesHelper } from './addons/mobile'

// SHARED APP COMPONENT che andranno rimossi:
// non USARE gli import globali, inserire nei singoli componenti quando necessari
// può semprare una comodità ed in effetti lo è, ma in caso di refactor è un delirio,
// soprattutto come in questo caso i nomi non coincidono con i file
// e gli script di analisi statica non riescono a creare la struttura corretta
import feAccordion from '@/components/global/accordion.vue'
import feButton from '@/components/global/button.vue'
import feIconComponent from '@/components/global/icon.vue'
import feSelectOptionComponent from '@/components/global/select-option.vue'
import feSelectComponent from '@/components/global/select.vue'
import feToggleComponent from '@/abs-core/shared/AbsIonToggle.vue'
import baseModal from '@/components/global/base-modal.vue'

/**
 * Initializes and mounts the main Vue.js application.
 * This function sets up the Vue.js application by registering global components,
 * creating a loader container and loader element, initializing helper modules,
 * and returning the initialized app and a mount function to start the app.
 *
 * @returns {object} - An object containing the initialized Vue.js app and a mount function to start the app.
 */
export function initializeApp() {
  const app = createApp(feApp)
    .use(IonicVue)
    .use(i18n)
    .use(router)
    .use(store)
    .use(Toast)
    .use(createPinia())

  // Global component registration - che andranno rimossi:
  app.component('FeIcon', feIconComponent)
  app.component('FeSelect', feSelectComponent)
  app.component('FeSelectOption', feSelectOptionComponent)
  app.component('FeToggle', feToggleComponent)
  app.component('FeAccordion', feAccordion)
  app.component('FeButton', feButton)
  app.component('BaseModal', baseModal)

  // Loader creation
  /**
   * Creates a loader container and loader element on the document body.
   * This is used to display a loading indicator for the application.
   */
  const loaderContainer = document.createElement('div')
  loaderContainer.setAttribute('id', 'pageloader-container')
  document.body.appendChild(loaderContainer)
  const loader = document.createElement('div')
  loader.setAttribute('id', 'pageloader')
  loaderContainer.appendChild(loader)

  /**
   * Initializes the AbortControllerHelper module.
   * The AbortControllerHelper module is responsible for managing AbortController instances
   * and providing a consistent way to cancel HTTP requests across the application.
   * This function should be called during application initialization to set up the AbortController functionality.
   */
  AbortControllerHelper.init()

  /**
   * Initializes the FeLogger module.
   * The FeLogger module is responsible for logging application events and errors.
   * This function should be called during application initialization to set up the logging functionality.
   */
  FeLogger.init()

  /**
   * Initializes the live update functionality for the application.
   * This function first checks if the store setup has been fetched, and if not,
   * it dispatches the action to fetch the store setup.
   * It then retrieves the deployment environment from the store
   * and maps it to the appropriate live update channel.
   * Finally, it initializes the LiveUpdatesHelper with the determined channel.
   */
  async function setLiveUpdate() {
    let channel = LIVE_UPDATE_DEFAULT_CHANNEL

    try {
      if (!store.getters[ConfigGetters.GET_STORE_SETUP]) {
        await store.dispatch(ConfigActions.FETCH_STORE_SETUP)
      }

      const deployEnv = store.getters[ConfigGetters.DEPLOY_ENV]
      channel = LIVE_UPDATE_CHANNEL_MAPPING[deployEnv]
      FeLogger.info('Mapped LiveUpdates channel to:', channel)
    } catch (err) {
      FeLogger.error('Error fetching store setup or mapping channel:', err)
    }

    await LiveUpdatesHelper.init({
      channel: channel,
    })
  }

  /**
   * Initializes and mounts the main Vue.js application
   * after preparing API helpers and waiting for the router to be ready.
   * The `mount: async function` is responsible for the final initialization and startup of the application.
   *
   * @returns {object} - An object containing the initialized Vue.js app and a mount function to start the app.
   */
  async function mountApp() {
    try {
      await prepareApiHelpers()
      await router.isReady()
      Log.info('Init app modules')
      await initModules() // XXX: moved from '/src/router/guards.ts' - line 110
      await setLiveUpdate()
      app.mount('#app')
      FeLogger.info('App successfully mounted')
      Log.info('App successfully mounted')
    } catch (error) {
      FeLogger.error('Error during app mount process:', error)
      Log.error('Error during app mount process: ' + error)
    }
  }

  return {
    app,
    mount: mountApp,
  }
}
