import { OMAuthenticationHelper } from '@/addons/authentication'
import {
  AbortControllerHelper,
  getAxiosConfigs,
  instantiateApiHelper,
  isValidCashIp,
  sessionApi,
  storeDataApi,
} from '@/addons/axios'
import { ConnectivityChecker } from '@/addons/connectivity'
import { CookieKeys, LanguagesIso, PageContexts } from '@/addons/enums'
import {
  getBaseURL,
  deleteCookie,
  getSid,
  setCookie,
  getCookie,
} from '@/addons/functions'
import { isNativeOrWebIos } from '@/addons/mobile'
import { FeLogger } from '@/addons/monitoring'
import { Session, SessionData } from '@/api'
import {
  PortalAuthenticationApiFactory,
  SecondFactorAuth,
  SuccessfulAuthentication,
} from '@/api/portale'
import router, { Routes } from '@/router'
import { AxiosResponse } from 'axios'
import { ActionTree } from 'vuex'
import { ConfigGetters, ConfigMutations } from '@/store/configs-store'
import { ContextPageActions } from '@/store/context-page/context-page'
import store, { RootState } from '@/store'
import { TypedActionContext } from '../_types'
import { NotificationsActions } from '../notifications-store'
import { GetterNames } from './_getters'
import { MutationNames, Mutations } from './_mutations'
import { AuthCallPayload, State } from './_state'
import LoaderController from '@/components/loader/LoaderController'
import { AuthenticationRequires2FAError } from './_errors'
import { clearLocalStorage } from '@/addons/persistence'

export enum ActionNames {
  RESET_STATE = 'resetState',
  SET_SID = 'setSid',
  GOTOLOGIN = 'goToLoginPage',
  LOGIN = 'login',
  LOGOUT = 'logout',
  AUTHENTICATE = 'authenticate',
  UPDATE_AUTH_STATUS = 'updateAuthStatus',
  FETCH_CASHES = 'fetchCashes',
  SELECT_CASH = 'selectCash',
  START_POST_LOGIN_ACTIONS = 'startPostLoginActions',
  RESET_STORAGE = 'reset-storage',
}

type AugmentedActionContext = TypedActionContext<Mutations, State, RootState>

function is2FAResponse(
  data: SuccessfulAuthentication | SecondFactorAuth,
): data is SecondFactorAuth {
  return 'force_login_sms' in data
}

const TWO_WEEKS = 1000 * 3600 * 24 * 14

export interface Actions {
  [ActionNames.RESET_STATE](context: AugmentedActionContext): void
  [ActionNames.SET_SID](context: AugmentedActionContext, sid: string): void
  [ActionNames.GOTOLOGIN](context: AugmentedActionContext): void
  [ActionNames.LOGIN](
    context: AugmentedActionContext,
    { username, password }: AuthCallPayload,
  ): Promise<void>
  [ActionNames.LOGOUT](context: AugmentedActionContext): Promise<void>
  [ActionNames.AUTHENTICATE](
    context: AugmentedActionContext,
    authApiResponseData: SessionData,
  ): void
  [ActionNames.UPDATE_AUTH_STATUS](
    context: AugmentedActionContext,
    language: keyof typeof LanguagesIso,
  ): Promise<void>
  [ActionNames.FETCH_CASHES](
    context: AugmentedActionContext,
    reloadCashes: boolean,
  ): Promise<void>
  [ActionNames.SELECT_CASH](
    context: AugmentedActionContext,
    selectedCashId: string,
  ): Promise<void>
  [ActionNames.START_POST_LOGIN_ACTIONS](
    context: AugmentedActionContext,
  ): Promise<void>
  [ActionNames.RESET_STORAGE](): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ActionNames.RESET_STATE](context) {
    context.commit(MutationNames.RESET_STATE)
  },
  [ActionNames.SET_SID](context, sid) {
    context.commit(MutationNames.SID, sid)
  },
  [ActionNames.LOGIN]: async (
    context,
    { username, password, secondFactor },
  ) => {
    const usePortalLogin = store.getters[ConfigGetters.USE_LOGIN_PORTAL]
    const portalURL = store.getters[ConfigGetters.GET_PORTAL_URL]
    let r: AxiosResponse<Session, unknown>

    if (usePortalLogin) {
      // If we have to use the centralized authentication provider,
      // make sure to instantiate the appropriate helpers.
      const basePath = new URL(portalURL).origin
      const configuration = getAxiosConfigs()
      const portalApis = PortalAuthenticationApiFactory(configuration, basePath)

      const device2FAUUID = await getCookie(CookieKeys.DEVICE_2FA_UUID)

      // Make the actual login request. If we received a 2FA code, we send that insted of the password.
      // Also, make sure to include the UUID assigned to this device during the previous login.
      const res = await portalApis.apiV2Post(
        username,
        secondFactor || password,
        'ws_interface',
        'login',
        'POS',
        'JSON',
        device2FAUUID,
      )

      if (is2FAResponse(res.data)) {
        // If Portale sent user a 2FA code, we have to show the appropriate input
        // field and we can clear any previously saved 2FA-related cookie.
        await deleteCookie(CookieKeys.DEVICE_2FA_UUID)
        throw new AuthenticationRequires2FAError()
      }

      // If something broke, return an error.
      if (res.data.status !== 'OK' || !res.data.payload?.sid) {
        throw new Error(res.data.status)
      }

      const portalSid = res.data.payload.sid
      const portalUUID = res.data.payload.MMFG_2fa_uuid

      // If Portale emitted an UUID for this device, make sure to save it.
      // The duration of the cookie itself is not too important: if Portale decides to
      // untrust this cookie, user will be challenged again with a 2FA request.
      if (portalUUID) {
        await setCookie(CookieKeys.DEVICE_2FA_UUID, portalUUID, TWO_WEEKS)
      }

      // At this point we can make a POST request to Posweb to convert the Portal SID into a Posweb SID
      r = await sessionApi.apiV1SessionsPortalSidPost(portalSid, {
        data: {
          type: 'session',
          attributes: {
            cod_cassa: context.rootState.configs?.setup?.cash_id,
          },
        },
      })
    } else {
      // Since we do not have to use the centralized authentication provider, we will do a local
      // login by making a POST request to the `/sessions` Posweb endpoint.
      r = await sessionApi.apiV1SessionsPost({
        data: {
          type: 'session',
          attributes: {
            username,
            password,
            cod_cassa: context.rootState.configs?.setup?.cash_id,
          },
        },
      })
    }
    await context.dispatch(ActionNames.AUTHENTICATE, r.data.data)
    await context.dispatch(ActionNames.FETCH_CASHES, null)

    const isMobile = store.getters[ConfigGetters.IS_MOBILE]

    // If user is on a mobile device, we have to wait to trigger the post
    // login actions as current user has to choose a cash register.
    if (!isMobile) {
      await context.dispatch(ActionNames.START_POST_LOGIN_ACTIONS)
      await router.push(Routes.DASHBOARD)
    }
  },
  [ActionNames.LOGOUT]: async (context) => {
    LoaderController.show({
      section: PageContexts.LOGIN,
    })
    // Cancel any pending request and re-Init AbortController
    AbortControllerHelper.abortAndInit()

    const sid = context.state.sid || (await getSid())
    if (sid) {
      try {
        await sessionApi.apiV1SessionsSidDelete(sid, {
          // Make sure we do not accidentally cancel this request.
          signal: undefined,
          headers: {
            Authorization: `Bearer ${sid}`,
          },
        })
        await context.dispatch(ActionNames.RESET_STORAGE)
      } catch (error) {
        FeLogger.error(error)

        await context.dispatch(
          NotificationsActions.NOTIFY_ERROR,
          'logout.error',
          {
            root: true,
          },
        )
      } finally {
        // Since we have a cookie that has proved to be invalid or has become
        // invalid after a logout, make sure to remove it.
        await deleteCookie(CookieKeys.SID)
      }
    }

    try {
      ConnectivityChecker.getInstance().stop()
      OMAuthenticationHelper.getInstance().stop()
    } catch (error) {
      FeLogger.warn(
        `Failed to stop connectivity checks and/ot OM authentication helpers`,
        error,
      )
    }

    await store.dispatch(ContextPageActions.RESET_CONTEXT_PAGE)

    // Reset global state and redirect user to the login page
    await context.dispatch(ActionNames.RESET_STATE)
    LoaderController.hide()
    await context.dispatch(ActionNames.GOTOLOGIN)
  },
  [ActionNames.GOTOLOGIN]: () => {
    window.location.href = getBaseURL() + Routes.LOGIN
  },
  [ActionNames.AUTHENTICATE]: (context, authApiResponseData) => {
    context.commit(MutationNames.IS_AUTHENTICATED, true)
    context.commit(MutationNames.SID, authApiResponseData?.id)
    context.commit(MutationNames.USER, authApiResponseData?.attributes)
  },
  [ActionNames.UPDATE_AUTH_STATUS]: async (context, language) => {
    const sid = context.state.sid || (await getSid())
    const posLang = LanguagesIso[language]
    if (sid) {
      const authApiResponse = await sessionApi.apiV1SessionsSidPatch(
        sid,
        {
          data: {
            type: 'session',
            id: sid,
            attributes: {
              language: posLang,
            },
          },
        },
        {
          signal: AbortControllerHelper.controller?.signal,
          headers: {
            Authorization: `Bearer ${sid}`,
          },
        },
      )
      if (authApiResponse.data) {
        return context.dispatch(
          ActionNames.AUTHENTICATE,
          authApiResponse.data.data,
        )
      }
    }
  },
  [ActionNames.FETCH_CASHES]: async (context, reloadCashes = false) => {
    if (context.getters[GetterNames.CASHES]?.length > 0 && !reloadCashes) {
      return Promise.resolve()
    }
    const cashesApiResponse = await storeDataApi.apiV1PoswebCashesGet()
    context.commit(MutationNames.CASHES, cashesApiResponse.data.data)
  },
  [ActionNames.SELECT_CASH]: async (context, selectedCashId) => {
    // This very next line should become redundant when (and if) we change the order of the cash select and login form.
    context.commit(
      `configs/${ConfigMutations.SET_SELECTED_CASH}`,
      selectedCashId,
      {
        root: true,
      },
    )
    await context.dispatch(ActionNames.START_POST_LOGIN_ACTIONS)
  },
  [ActionNames.START_POST_LOGIN_ACTIONS]: async (context) => {
    const selectedCashId = store.getters[ConfigGetters.SELECTED_CASH]
    const isLive = store.getters[ConfigGetters.IS_LIVE_ENVIRONMENT]
    // If this condition is true, user is using a mobile device (such as an iPad)
    if (isNativeOrWebIos() && selectedCashId) {
      // In this case we have to make subsequent requests directly to the selected cash

      const cash = context.state.cashes.find(
        (c) => c.attributes?.cod_cassa === selectedCashId,
      )

      if (cash?.attributes) {
        const protocol = cash.attributes?.ip_protocol || 'http'
        const ipAddress = cash.attributes.ip_address || ''
        const port = cash.attributes.ip_port || '8000'

        const cashUrl = `${protocol}://${ipAddress}:${port}`

        // If we are in a production environment, we have a valid cash IP and we were not forcefully requested to
        // route all HTTP traffic trough the master cash, we can we can initialize API helpers once again, this
        // time providing the appropriate base url.
        if (
          isLive &&
          isValidCashIp(ipAddress) &&
          !import.meta.env.VITE_ROUTE_THROUGH_MASTER_CASH
        ) {
          if (await ConnectivityChecker.isReachable(cashUrl)) {
            instantiateApiHelper(cashUrl)
            // Make sure to keep track of this URL so that we can reuse it later on if needed.
            store.commit(
              `configs/${ConfigMutations.SET_SELECTED_CASH_URL}`,
              cashUrl,
              { root: true },
            )
          } else {
            store.dispatch(
              NotificationsActions.NOTIFY_WARN,
              'common.unreachable_cash_register',
              { root: true },
            )
            return Promise.reject(
              new Error(`Unreachable cash (URL ${cashUrl})`),
            )
          }
        } else {
          store.dispatch(
            NotificationsActions.NOTIFY_WARN,
            'common.invalid_cash_configuration_wrong_ip',
            { root: true },
          )
        }
      }
    }
  },
  [ActionNames.RESET_STORAGE]: () => {
    clearLocalStorage()
    sessionStorage.clear()
    return Promise.resolve()
  },
}
