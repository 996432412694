import router, { isLoginPage } from '@/router'
import store from '@/store'
import { AuthActions, AuthGetters } from '@/store/auth'
import i18n, {
  loadRouteMessages,
  loadRouteMessagesFromLocalJson,
} from '@/addons/i18n'
import { getSid } from '@/addons/functions'
import LoaderController from '@/components/loader/LoaderController'
import { PageContexts } from '@/addons/enums'

export function setupRouter() {
  /**
   * Middleware function that runs before each route navigation in the application.
   * It handles the following tasks:
   * - Shows a loading indicator when navigating to a non-login page
   * - Ensures the user's session ID (SID) is set in the Vuex store, if it exists in a cookie
   * - Redirects the user to the login page if they are not authenticated (no SID in the store)
   * - Loads the appropriate locale messages for the current route
   *
   * @param {Object} to - The route being navigated to.
   * @param {Object} _from - The route being navigated from (not used).
   * @param {Function} next - The function to call to continue the navigation.
   * @returns {Promise<boolean|void>} - Returns `false` if the navigation should be cancelled, otherwise calls `next()`.
   */
  router.beforeEach(async (to, _from, next) => {
    if (!isLoginPage(_from)) {
      const pageContext = to.meta.context
        ? to.meta.context
        : PageContexts.GENERIC
      LoaderController.show({
        section: pageContext,
      })
    }

    // If we got a SID cookie, but we do not have that value in the auth store, we have to set it.
    // This way, subsequent requests work as expected. If the SID turns out to be invalid (for whatever reason),
    // user will be redirected to the login page since HTTP requests would fail with a 401 Unauthorized.
    if (!store.getters[AuthGetters.GET_SID]) {
      const sid = await getSid()
      if (sid) {
        await store.dispatch(AuthActions.SET_SID, sid, { root: true })
      }
    }

    const hasSid = !!store.getters[AuthGetters.GET_SID]

    // If user tries to load something that is not the login page, we have to perform some checks.
    if (!isLoginPage(to) && !hasSid) {
      // If we have no sid, user is definitely unauthenticated: redirect him/her to the login page.
      await store.dispatch(AuthActions.GOTOLOGIN)

      // Since we found out that we cannot proceed, we have to signal that so that we do not make
      // additional requests that will lead to a redirect loop (this especially true in Safari).
      return false
    }

    if (isLoginPage(to)) {
      await loadRouteMessagesFromLocalJson(
        i18n,
        to.meta.localesModules as string[],
      )
    } else {
      await loadRouteMessages(i18n, to.meta.localesModules as string[])
    }

    return next()
  })

  router.afterEach(() => {
    LoaderController.hide()
  })
}
