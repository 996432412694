import { PageContexts } from '@/addons/enums'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import { authorizationGuard, beforeEnter, salesDataService } from './guards'
import { Routes, RoutesName } from './routes'

/**
 * A special flag used to indicate that this route should not be subject to the
 * authorization guard. This is meant to be used as an escape hatch for those
 * situations in which we do not have an updated menu item corresponding to this page.
 * If the page is meant to be accessible by any user, such as the login and dashboard
 * pages, make sure to use the `isUnprivileged` flag insted of this one.
 * Keep in mind that this flag works only if we are not in production. Moreover,
 * when a page is accessed because of this flag, we will log a warning in console and in DataDog.
 *
 * ! Note: this flag is not automatically propagated to child pages. It has to be set manually on each one of them.
 *
 * @see RouteMeta.isUnprivileged
 */
export const __BYPASS_AUTH_GUARD = Symbol.for('BYPASS_AUTH_GUARD')

/**
 * An array of shared local module names used in the 'routes' array.
 * These modules were used across different parts of the routes.
 */
const sharedLocales = [
  'mmfg_menu',
  'mmfg_season',
  'pos_consumer',
  'pos_cash_register',
  'pos_digital_card',
  'pos_endless_aisle',
  'pos_loyalty',
  'pos_loyalty_card',
]

/**
 * @description Routes and views structure.
 * Each top level path is rendered in the "<router-view>" defined in the entrypoint component (that is app.vue).
 * This means that the login page and the special "RouterView.vue" view will be rendered in the structure defined in the app.vue file.
 * Nested inside the "/" route, we have all app routes (except for the login one).
 * Those routes are rendered inside a dedicated <router-view> (defined in RouterView.vue), since each RouteRecordRaw must define a component.
 * Doing all of this, we can apply a route-level guard and consider it as if it was global (except for the fact that does
 * not affect the /login path) and we can have proper redirects.
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: Routes.LOGIN,
    component: () => import('@/views/login.vue'),
    meta: {
      isUnprivileged: true,
      localesModules: ['login', 'login-form'],
    },
  },
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/RouterView.vue'),
    redirect: Routes.DASHBOARD,
    beforeEnter: beforeEnter,
    children: [
      {
        path: Routes.DASHBOARD,
        component: () => import('@/views/dashboard.vue'),
        children: [
          {
            name: RoutesName.DASHBOARD_SUBMENU,
            path: Routes.DASHBOARD_SUBMENU,
            component: () => import('@/views/dashboard/submenu.vue'),
            meta: {
              isUnprivileged: true,
              localesModules: [
                'mmfg_menu',
                'mmfg_season',
                'pos_endless_aisle',
                'pos_digital_card',
                'pos_loyalty_card',
                'modal',
              ],
            },
          },
        ],
        meta: {
          isUnprivileged: true,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_consumer_languages',
            'pos_sale',
            'codici_lingue',
            'pos_dashboard',
            'pos_closure',
          ],
        },
      },
      {
        path: Routes.SALES,
        component: () => import('@/views/sales.vue'),
        beforeEnter: salesDataService,
        meta: {
          context: PageContexts.FRONTOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'codici_lingue',
            'pos_address',
            'pos_barcode',
            'pos_common',
            'pos_common_privacy',
            'pos_consumer_languages',
            'pos_free_return',
            'pos_gift_card',
            'pos_movement_type',
            'pos_oss',
            'pos_payment_type',
            'pos_promo_engine',
            'pos_resi',
            'pos_returns',
            'pos_sale',
            'pos_sosp',
            'pos_stock',
            'pos_suspended_sale',
          ],
        },
      },
      {
        path: Routes.RECAP,
        component: () => import('@/views/recap.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          sameAccessRulesAs: [Routes.SALES],
          localesModules: ['pos_document_type'],
        },
      },
      {
        path: Routes.STOCK,
        component: () => import('@/views/stock.vue'),
        meta: {
          context: PageContexts.STOCK,
          localesModules: [
            'mmfg_season',
            'pos_cash_register',
            'pos_digital_card',
            'pos_endless_aisle',
            'pos_loyalty',
            'pos_loyalty_card',
            // .
            'pos_sale',
          ],
        },
      },
      {
        path: Routes.CASH_CHECK,
        component: () => import('@/views/cash-check.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_contr_cassa',
            'pos_payment_type',
          ],
        },
      },
      {
        path: Routes.DEPOSITS,
        component: () => import('@/views/deposits/deposits.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_deposit',
            'pos_restore',
          ],
        },
      },
      {
        path: Routes.DEPOSITS_LIST,
        component: () => import('@/views/deposits/deposits-list.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          sameAccessRulesAs: [Routes.DEPOSITS],
          localesModules: [
            'mmfg_menu',
            'pos_cash_register',
            'pos_deposit',
            // .
          ],
        },
      },
      {
        path: Routes.PETTYCASH,
        component: () => import('@/views/petty-cash/petty-cash.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_deposit',
            'pos_restore',
            'pos_petty_cash',
          ],
        },
      },
      {
        path: Routes.PETTY_CASH_LIST,
        component: () => import('@/views/petty-cash/petty-cash-list.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          sameAccessRulesAs: [Routes.PETTYCASH],
          localesModules: [
            ...sharedLocales,
            // .
            'pos_deposit',
            'pos_restore',
            'pos_petty_cash',
          ],
        },
      },
      {
        path: Routes.RETURNS,
        component: () => import('@/views/returns/returns.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_common',
            'pos_restore',
            'pos_document_type',
            'pos_resi',
            'pos_petty_cash',
            'kpi_selection_form',
            'pos_pdf_sales_report',
            'pos_movement_type',
            'pos_payment_type',
            'pos_returns',
          ],
        },
      },
      {
        path: Routes.RECAP_RETURNS,
        component: () => import('@/views/recap-returns.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          sameAccessRulesAs: [Routes.RETURNS],
          localesModules: [
            'pos_loyalty',
            'pos_loyalty_card',
            // .
            'pos_document_type',
            'pos_common',
            'pos_payment_type',
          ],
        },
      },
      {
        path: Routes.SALES_LIST,
        component: () => import('@/views/sales-list/sales-list.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_common',
            'pos_restore',
            'pos_document_type',
            'pos_movement_type',
            'conteggio_banconote',
          ],
        },
      },
      {
        name: RoutesName.SALES_DETAIL,
        path: Routes.SALES_DETAIL,
        component: () => import('@/views/sales-list/sales-detail.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          sameAccessRulesAs: [Routes.SALES_LIST],
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_common',
            'pos_movement_type',
            'pos_payment_type',
            'pos_petty_cash',
            'pos_crm_contact_type',
          ],
        },
      },
      {
        path: Routes.PRICE_UPDATE,
        component: () => import('@/views/price-update.vue'),
        meta: {
          context: PageContexts.STOCK,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_update_prices',
          ],
        },
      },
      {
        path: Routes.CASH_CLOSING,
        component: () => import('@/views/cash-closing.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_closure',
          ],
        },
      },
      {
        path: Routes.GIFT_CARD_BALANCE,
        component: () => import('@/views/gift-card-balance.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_tools',
            'pos_giftcard_balance',
          ],
        },
      },
      {
        path: Routes.REFUND_B2E,
        component: () => import('@/views/refund-b2e.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_rimborsi_b2e',
            'pos_resi_b2c',
            'pos_payment_type',
            'pos_sale',
          ],
        },
      },
      {
        path: Routes.SUSPENDED_CREDIT,
        component: () =>
          import('@/views/suspended-credit/suspended-credit.vue'),
        beforeEnter: salesDataService,
        meta: {
          context: PageContexts.FRONTOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_common',
            'pos_payment_type',
            'pos_payment',
            'pos_sosp',
            'pos_movement_type',
            'pos_consumer_languages',
          ],
        },
      },
      {
        path: Routes.SUSPENDED_CREDIT_PERFORMED_PAYMENTS,
        component: () =>
          import('@/views/suspended-credit/performed-payments.vue'),
        beforeEnter: salesDataService,
        meta: {
          context: PageContexts.FRONTOFFICE,
          sameAccessRulesAs: [Routes.SUSPENDED_CREDIT],
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_common',
            'pos_payment_type',
            'pos_payment',
            'pos_sosp',
            'pos_movement_type',
            'pos_consumer_languages',
          ],
        },
      },
      {
        path: Routes.ALL_SUSPENDED_CREDITS,
        component: () =>
          import('@/views/suspended-credit/all-suspended-credits.vue'),
        beforeEnter: salesDataService,
        meta: {
          context: PageContexts.FRONTOFFICE,
          sameAccessRulesAs: [Routes.SUSPENDED_CREDIT],
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_common',
            'pos_payment_type',
            'pos_payment',
            'pos_sosp',
            'pos_movement_type',
            'pos_consumer_languages',
          ],
        },
      },
      {
        path: Routes.LAYBY,
        component: () => import('@/views/layby/layby-list.vue'),
        meta: {
          context: PageContexts.FRONTOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_sale',
            'pos_common',
            'pos_restore',
            'pos_document_type',
            'conteggio_banconote',
            'pos_layby',
          ],
        },
      },
      {
        path: Routes.BANKS,
        component: () => import('@/views/banks.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_common',
            'pos_bank',
          ],
        },
      },
      {
        path: Routes.USERS,
        component: () => import('@/views/users/users.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_users',
            'codici_lingue',
          ],
        },
      },
      {
        path: Routes.TOOLS,
        component: () => import('@/views/tools/tools.vue'),
        meta: {
          context: PageContexts.BACKOFFICE,
          localesModules: [
            ...sharedLocales,
            // .
            'pos_tools',
            'pos_common',
          ],
        },
      },
      // A special route to render older pages within an iframe.
      {
        path: Routes.PREVIOUS_UI_PAGES,
        component: () => import('@/views/iframe.vue'),
      },
    ],
  },
  {
    // A special route to redirect all unmatched paths to dashboard.
    // This route MUST be defined as the last one.
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: Routes.DASHBOARD,
  },
]

/**
 * Creates a new Vue Router instance with the provided routes.
 * The history mode is set to `createWebHistory` using the `VITE_WEB_APP_PATH_PREFIX` environment variable.
 *
 * Note: we do not use `BASE_URL` as it's different across the iOS and web apps and we want consistent path prefixes.
 */
const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_WEB_APP_PATH_PREFIX),
  routes,
})

/**
 * Adds a new route to the Vue Router instance.
 *
 * @param {RouteRecordRaw} route - The new route information, including component, meta, and path.
 * @param {string} [parentName] - The name of the parent route, if the new route is a child route.
 * @returns {void}
 */
export function addRoute(route: RouteRecordRaw, parentName?: string): void {
  router.addRoute(parentName ?? '', route)
}

// Make sure to check whether current user can access the requested page.
router.beforeResolve((to) => authorizationGuard(to))

export default router
export { Routes, RoutesName, isLoginPage, isRoute } from './routes'
