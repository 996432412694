/**
 * This store is used to manage the modification of a product
 * keeps the states of the various components that make up the edit-article drawer aligned
 */
import {
  ApiV1SalesDataAttributesCapi,
  ApiV1SalesDataAttributesPagamenti,
  ApiV1SalesSaleIdDataAttributesCapi,
  ApiV1SalesSaleIdDataAttributesPagamenti,
  GetCashiersAttributes,
  GetItemsPriceAlteration,
  GetItemsPriceAlterationAttributes,
  GetItemsPriceAlterationData,
  GetSkuDataAttributes,
  GetSkuDataAttributesComposizione,
} from '@/api'
import { ActionContext } from 'vuex'
import store, { RootState } from '@/store'
import { SalesActions, SalesGetters } from '@/store/sales/sales-store'
import { CashiersGetters } from '@/store/cashier'
import { roundDecimals } from '@/addons/adjustments'
import { itemsApi } from '@/addons/axios'
import { AxiosResponse } from 'axios'
import { TipiMovimenti, TipiVendita, StockIcon } from '@/addons/enums'
import { DrawerActions } from '@/store/drawer-store'
import { LocalComposizioneItem } from '@/interfaces/sales-model'
import { mapComposizione } from '@/addons/composition'
import { stockIcon } from '@/addons/functions/sales'

export interface ProductState {
  product?: GetSkuDataAttributes
  capo?: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi
  progressivo?: number
  selectedRDV?: GetCashiersAttributes // cod_commessa di capo
  correzione_percentuale: number
  alterations: GetItemsPriceAlterationAttributes[]
  sartorie:
    | ApiV1SalesDataAttributesPagamenti[]
    | ApiV1SalesSaleIdDataAttributesPagamenti[]
  barcode: string | null
  freeReturn: boolean
}

enum Mutations {
  RESET_STATE = 'reset-state',
  STORE_PROGRESSIVO = 'store-progressivo',
  CLEAR_PROGRESSIVO = 'clear-progressivo',
  STORE_CAPO = 'store-capo',
  CLEAR_CAPO = 'clear-capo',
  STORE_PRODUCT = 'store-product',
  CLEAR_PRODUCT = 'clear-product',
  STORE_RDV = 'store-rdv',
  CLEAR_RDV = 'clear-rdv',
  STORE_CORREZIONE_PERCENTUALE = 'store-correzione-percentuale',
  CLEAR_CORREZIONE_PERCENTUALE = 'clear-correzione-percentuale',
  STORE_IMPORTO_FINALE = 'store-importo-finale',
  CLEAR_IMPORTO_FINALE = 'clear-importo-finale',
  STORE_IMPORTO_CUSTOM = 'store-importo-custom',
  CLEAR_IMPORTO_CUSTOM = 'clear-importo-custom',
  STORE_IMPORTO_CORREZIONE = 'store-importo-correzione',
  CLEAR_IMPORTO_CORREZIONE = 'clear-importo-correzione',
  STORE_IMPORTO_TIPO = 'store-importo-tipo',
  CLEAR_IMPORTO_TIPO = 'clear-importo-tipo',
  STORE_IMPORTO_PRODOTTO_INIZIALE = 'store-importo-prodotto-iniziale',
  STORE_CODICE_MOVIMENTO = 'store-codice-movimento',
  CLEAR_CODICE_MOVIMENTO = 'clear-codice-movimento',
  STORE_IVA = 'store-iva',
  CLEAR_IVA = 'clear-iva',
  STORE_ALTERATIONS = 'store-alterations',
  CLEAR_ALTERATIONS = 'clear-alterations',
  STORE_SARTORIE = 'store-sartorie',
  CLEAR_SARTORIE = 'clear-sartorie',
  STORE_NOTE = 'store-note',
  CLEAR_NOTE = 'clear-note',
  STORE_CONSEGNA_DATA = 'store-consegna-data',
  CLEAR_CONSEGNA_DATA = 'clear-consegna-data',
  STORE_NAME = 'store-name',
  STORE_CLASS = 'store-classe',
  STORE_COMPOSITION = 'store-composition',
  STORE_IMPORTO_INIZIALE = 'store-importo-iniziale',
  STORE_BARCODE = 'store-barcode',
  DELETE_BARCODE = 'delete-barcode',
  STORE_SCONTO = 'store-sconto',
}

enum Actions {
  RESET_STATE = 'reset-state',
  EDIT_PRODUCT = 'edit-product',
  UNDO_PRODUCT = 'undo-product',
  SAVE_PRODUCT = 'save-product',
  SET_OMAGGIO = 'set-omaggio',
  SET_RDV = 'set-rdv',
  SET_IMPORTO_TIPO = 'set-importo-tipo',
  SET_CODICE_MOVIMENTO = 'set-codice-movimento',
  SET_IVA = 'set-iva',
  SET_CORREZIONE_IMPORTO = 'set-correzione-importo',
  SET_CORREZIONE_PERCENTUALE = 'set-correzione-percentuale',
  SET_IMPORTO_CUSTOM = 'set-importo-custom',
  SET_IMPORTO_PRODOTTO_INIZIALE = 'set-importo-prodotto-iniziale',
  FETCH_ALTERATIONS = 'fetch-alterations',
  ADD_SARTORIA = 'add-sartoria',
  REMOVE_SARTORIA = 'remove-sartoria',
  SET_SARTORIA_PRICE = 'set-sartoria-price',
  RESET_SARTORIA_PRICE = 'reset-sartoria-price',
  SET_SARTORIA_DESCRIPTION = 'set-sartoria-description',
  SET_NOTA = 'set-nota',
  RESET_NOTA = 'reset-nota',
  SET_CONSEGNA = 'set-consegna',
  RESET_CONSEGNA = 'reset-consegna',
  UPDATE_IMPORTO_FINALE = 'update-importo-finale',
  STORE_NAME = 'store-name',
  STORE_CLASS = 'store-classe',
  STORE_COMPOSITION = 'store-composition',
  STORE_IMPORTO_INIZIALE = 'store-importo-iniziale',
  SET_BARCODE = 'store-barcode',
  DELETE_BARCODE = 'delete-barcode',
  CHANGE_TYPE = 'change-type',
}

enum Getters {
  GET_PROGRESSIVO = 'get-progressivo',
  GET_CAPO = 'get-capo',
  GET_PRODUCT = 'get-product',
  GET_RDV = 'get-rdv',
  GET_CORREZIONE_PERCENTUALE = 'get-correzione-percentuale',
  GET_ALTERATIONS = 'get-alterations',
  GET_SARTORIE = 'get-sartorie',
  GET_SARTORIE_PRICE = 'get-sartorie-price',
  GET_COMPOSIZIONE = 'get-composizione',
  GET_NOTA = 'get-nota',
  GET_DATA = 'get-data',
  GET_BARCODE = 'get-barcode',
}

const initState = (): ProductState => ({
  product: undefined,
  capo: undefined,
  progressivo: 0,
  selectedRDV: undefined,
  correzione_percentuale: 0,
  alterations: [],
  sartorie: [],
  barcode: null,
  freeReturn: false,
})

const productStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.RESET_STATE]: (state: ProductState): void => {
      Object.assign(state, initState())
    },
    [Mutations.STORE_BARCODE]: (
      state: ProductState,
      barcode: string | null,
    ): void => {
      state.barcode = barcode
    },
    [Mutations.DELETE_BARCODE]: (state: ProductState): void => {
      state.barcode = null
    },
    [Mutations.STORE_PROGRESSIVO]: (
      state: ProductState,
      progressivo: number,
    ): void => {
      state.progressivo = progressivo
    },
    [Mutations.CLEAR_PROGRESSIVO]: (state: ProductState): void => {
      state.progressivo = undefined
    },
    [Mutations.STORE_CAPO]: (
      state: ProductState,
      capo: ApiV1SalesDataAttributesCapi | ApiV1SalesSaleIdDataAttributesCapi,
    ): void => {
      state.capo = { ...capo }
    },
    [Mutations.STORE_IMPORTO_PRODOTTO_INIZIALE]: (
      state: ProductState,
      importo: number,
    ): void => {
      state.product.prezzo_iniziale = importo
    },
    [Mutations.CLEAR_CAPO]: (state: ProductState): void => {
      state.capo = undefined
    },
    [Mutations.STORE_PRODUCT]: (
      state: ProductState,
      product: GetSkuDataAttributes,
    ): void => {
      state.product = { ...product }
    },
    [Mutations.CLEAR_PRODUCT]: (state: ProductState): void => {
      state.product = undefined
    },
    [Mutations.STORE_RDV]: (
      state: ProductState,
      rdv: GetCashiersAttributes,
    ): void => {
      state.selectedRDV = { ...rdv }
      if (state.capo) {
        state.capo.cod_commessa = rdv?.venditrice
      }
    },
    [Mutations.CLEAR_RDV]: (state: ProductState): void => {
      state.selectedRDV = undefined
      if (state.capo) {
        state.capo.cod_commessa = undefined
      }
    },
    [Mutations.STORE_CORREZIONE_PERCENTUALE]: (
      state: ProductState,
      percentuale: number,
    ): void => {
      // this has to always be an integer (rounded down)
      state.correzione_percentuale = !isNaN(percentuale)
        ? Math.trunc(percentuale)
        : 0
    },
    [Mutations.CLEAR_CORREZIONE_PERCENTUALE]: (state: ProductState): void => {
      state.correzione_percentuale = 0
    },
    [Mutations.STORE_IMPORTO_FINALE]: (
      state: ProductState,
      importo: number,
    ): void => {
      if (state.capo) {
        state.capo.importo_finale = importo
      }
    },
    [Mutations.CLEAR_IMPORTO_FINALE]: (state: ProductState): void => {
      if (state.capo) {
        state.capo.importo_finale = 0
      }
    },
    [Mutations.STORE_IMPORTO_CUSTOM]: (
      state: ProductState,
      importo: number,
    ): void => {
      if (state.capo) {
        state.capo.importo_custom = importo
      }
    },
    [Mutations.CLEAR_IMPORTO_CUSTOM]: (state: ProductState): void => {
      if (state.capo) {
        state.capo.importo_finale = 0
      }
    },
    [Mutations.STORE_IMPORTO_CORREZIONE]: (
      state: ProductState,
      importo: number,
    ): void => {
      if (state.capo) {
        state.capo.correzione_importo = importo
      }
    },
    [Mutations.CLEAR_IMPORTO_CORREZIONE]: (state: ProductState): void => {
      if (state.capo) {
        state.capo.correzione_importo = 0
      }
    },
    [Mutations.STORE_IMPORTO_TIPO]: (
      state: ProductState,
      tipo: string,
    ): void => {
      if (state.capo) {
        state.capo.tipo_importo = tipo
      }
    },
    [Mutations.CLEAR_IMPORTO_TIPO]: (state: ProductState): void => {
      if (state.capo) {
        state.capo.tipo_importo = ''
      }
    },
    [Mutations.STORE_CODICE_MOVIMENTO]: (
      state: ProductState,
      codice: string,
    ): void => {
      if (state.capo) {
        state.capo.codice_movimento = codice
      }
    },
    [Mutations.CLEAR_CODICE_MOVIMENTO]: (state: ProductState): void => {
      if (state.capo) {
        state.capo.codice_movimento = ''
      }
    },
    [Mutations.STORE_IVA]: (state: ProductState, iva: number): void => {
      if (state.capo) {
        state.capo.iva = iva
      }
      if (state.product?.flag_created) {
        state.product.iva = iva
      }
    },
    [Mutations.CLEAR_IVA]: (state: ProductState): void => {
      if (state.capo) {
        state.capo.iva = 0
      }
    },
    [Mutations.STORE_ALTERATIONS]: (
      state: ProductState,
      alterations: GetItemsPriceAlterationAttributes[],
    ): void => {
      state.alterations = alterations
    },
    [Mutations.CLEAR_ALTERATIONS]: (state: ProductState): void => {
      state.alterations = []
    },
    [Mutations.STORE_SARTORIE]: (
      state: ProductState,
      sartorie:
        | ApiV1SalesDataAttributesPagamenti[]
        | ApiV1SalesSaleIdDataAttributesPagamenti[],
    ): void => {
      state.sartorie = sartorie
    },
    [Mutations.CLEAR_SARTORIE]: (state: ProductState): void => {
      state.sartorie = []
    },
    [Mutations.STORE_NOTE]: (state: ProductState, note: string): void => {
      if (state.capo) {
        state.capo.custom_data = JSON.stringify({
          ...JSON.parse(state.capo.custom_data as string),
          nota_lavorazioni: note,
        })
      }
    },
    [Mutations.CLEAR_NOTE]: (state: ProductState): void => {
      if (state.capo) {
        const data = JSON.parse(state.capo.custom_data || '{}')
        delete data.nota_lavorazioni
        state.capo.custom_data = JSON.stringify(data)
      }
    },
    [Mutations.STORE_CONSEGNA_DATA]: (
      state: ProductState,
      data: string,
    ): void => {
      if (state.capo) {
        state.capo.custom_data = JSON.stringify({
          ...JSON.parse(state.capo.custom_data as string),
          data_consegna_sartoria: data,
        })
      }
    },
    [Mutations.CLEAR_CONSEGNA_DATA]: (state: ProductState): void => {
      if (state.capo) {
        const customData = JSON.parse(state.capo.custom_data as string)
        delete customData.data_consegna_sartoria
        state.capo.custom_data = JSON.stringify(customData)
      }
    },
    [Mutations.STORE_NAME]: (state: ProductState, name: string): void => {
      if (state.capo) {
        state.capo.nome = name
      }
    },
    [Mutations.STORE_CLASS]: (state: ProductState, classe: string): void => {
      if (state.capo) {
        state.capo.classe = classe
      }
      if (state.product) {
        state.product.classe = classe
      }
    },
    [Mutations.STORE_COMPOSITION]: (
      state: ProductState,
      composition: GetSkuDataAttributesComposizione,
    ): void => {
      if (state.capo) {
        state.capo.composizione = composition
      }
      if (state.product) {
        state.product.composizione = composition
      }
    },
    [Mutations.STORE_IMPORTO_INIZIALE]: (
      state: ProductState,
      importo: number,
    ): void => {
      if (state.capo) {
        state.capo.importo_iniziale = importo
        state.capo.importo_finale = importo
      }
    },
    [Mutations.STORE_SCONTO]: (state: ProductState, sconto: number): void => {
      if (state.capo) {
        state.capo.sconto = sconto || 0
      }
    },
  },
  actions: {
    [Actions.RESET_STATE]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.RESET_STATE)
    },
    [Actions.SET_BARCODE]: (
      context: ActionContext<ProductState, RootState>,
      barcode: string,
    ): void => {
      context.commit(Mutations.STORE_BARCODE, barcode)
    },
    [Actions.DELETE_BARCODE]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.DELETE_BARCODE)
    },
    [Actions.EDIT_PRODUCT]: (
      context: ActionContext<ProductState, RootState>,
      progressivo: number,
    ): void => {
      context.commit(Mutations.CLEAR_CORREZIONE_PERCENTUALE)
      context.commit(Mutations.STORE_PROGRESSIVO, progressivo)
      context.commit(
        Mutations.STORE_PRODUCT,
        context.rootGetters[SalesGetters.GET_PRODUCT](progressivo),
      )

      const p:
        | ApiV1SalesDataAttributesCapi
        | ApiV1SalesSaleIdDataAttributesCapi =
        context.rootGetters[SalesGetters.GET_CAPO](progressivo)
      if (p?.cod_commessa) {
        context.commit(
          Mutations.STORE_RDV,
          context.rootGetters[CashiersGetters.LIST].find(
            (c: GetCashiersAttributes) => c.venditrice === p.cod_commessa,
          ),
        )
      } else {
        context.commit(
          Mutations.STORE_RDV,
          context.rootGetters[SalesGetters.GET_RDV],
        )
      }
      context.commit(Mutations.STORE_CAPO, p)
      if (p?.correzione_importo) {
        context.dispatch(Actions.SET_CORREZIONE_IMPORTO, p.correzione_importo)
      }
      context.commit(
        Mutations.STORE_SARTORIE,
        context.rootState.sales.currentSale.pagamenti?.filter(
          (p) =>
            p.progressivo_capo === progressivo &&
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              p.codice_movimento ===
                TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO),
        ),
      )
      context.dispatch(Actions.FETCH_ALTERATIONS)
    },
    [Actions.UNDO_PRODUCT]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.CLEAR_PROGRESSIVO)
      context.commit(Mutations.CLEAR_PRODUCT)
      context.commit(Mutations.CLEAR_CAPO)
      context.commit(Mutations.CLEAR_RDV)
      context.commit(Mutations.CLEAR_CORREZIONE_PERCENTUALE)
      context.commit(Mutations.CLEAR_SARTORIE)
    },
    [Actions.SAVE_PRODUCT]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      if (
        context.state.capo?.codice_movimento === TipiVendita.RESO ||
        context.state.capo?.codice_movimento === TipiVendita.STORNO
      ) {
        context.state.capo.importo_iniziale =
          Math.abs(context.state?.capo?.importo_iniziale || 0) * -1
        context.state.capo.importo_finale =
          Math.abs(context.state?.capo?.importo_finale || 0) * -1
        store.dispatch(SalesActions.SET_CODICE_MOVIMENTO, TipiVendita.STORNO)
      } else {
        context.state.capo.importo_iniziale = Math.abs(
          context.state?.capo?.importo_iniziale || 0,
        )
        context.state.capo.importo_finale = Math.abs(
          context.state?.capo?.importo_finale || 0,
        )
        store.dispatch(SalesActions.SET_CODICE_MOVIMENTO, TipiVendita.VENDITA)
      }
      if (context.state.product?.flag_created) {
        context.dispatch(
          SalesActions.UPDATE_PRODUCT_DETAIL,
          context.state.capo,
          { root: true },
        )
        if (context.state.capo) {
          context.state.capo.sku_created = 1
          context.state.capo.sconto_listino_vendita = context.state.capo.sconto
          context.state.capo.causale_reso = ''
          context.state.capo.prezzo_listino_vendita =
            context.state.capo.importo_iniziale
        }
      }

      if (context.state.product?.prezzo_iniziale === 0) {
        context.state.capo.correzione_importo = 0
      }

      const st = stockIcon(context.state.capo)

      const customPriceAllowedStockTypes = [
        StockIcon.NOLISTDATA,
        StockIcon.NODATASTOCK,
      ]
      const articleInfo = context.state.capo

      // if the article has a custom price assigned we update the product_detail object in sales store
      if (st === StockIcon.NOLISTDATA) {
        context.dispatch(SalesActions.UPDATE_PRODUCT_DETAIL, articleInfo, {
          root: true,
        })
      }

      // if the article has a stock price we don't pass importo_custom onto the sales store
      if (!customPriceAllowedStockTypes.includes(st)) {
        articleInfo.importo_custom = 0
      }
      context.dispatch(SalesActions.UPDATE_CAPO, articleInfo, {
        root: true,
      })
      const tmpSartorie = [...context.state.sartorie]
      context.rootState.sales.currentSale.pagamenti
        ?.filter(
          (p) =>
            p.progressivo_capo === context.state.capo?.progressivo &&
            (p.codice_movimento === TipiMovimenti.CONTABILITA_SARTORIA ||
              p.codice_movimento ===
                TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO),
        )
        .forEach((p) => {
          context.dispatch(SalesActions.REMOVE_PAYMENT, p.progressivo, {
            root: true,
          })
        })
      let _priceSartorie = 0
      tmpSartorie.forEach((s) => {
        if (s.importo_finale === 0) {
          s.codice_movimento = TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO
        }

        _priceSartorie += s.importo_finale ?? 0
        context.dispatch(SalesActions.ADD_PAYMENT, s, { root: true })
      })

      /* context.commit(Mutations.STORE_IMPORTO_CORREZIONE,
        roundDecimals(
          priceSartorie,
          parseInt(context.rootState.configs.currentStore?.DECIMAL_PLACES_ROUNDING as string)
        ))
      context.commit(Mutations.STORE_IMPORTO_FINALE,
        roundDecimals(
          (context.state.capo?.importo_iniziale ?? 0) + (context.state.capo?.correzione_importo ?? 0),
          parseInt(context.rootState.configs.currentStore?.DECIMAL_PLACES_ROUNDING as string)
        ))
      context.commit(Mutations.STORE_IMPORTO_CUSTOM,
        roundDecimals(
          (context.state.capo?.importo_iniziale ?? 0) + (context.state.capo?.correzione_importo ?? 0),
          parseInt(context.rootState.configs.currentStore?.DECIMAL_PLACES_ROUNDING as string)
        ))*/
      context.dispatch(Actions.UNDO_PRODUCT)
      if (context.rootGetters[SalesGetters.GET_COUPON]) {
        context.dispatch(SalesActions.VALIDATE_COUPON, undefined, {
          root: true,
        })
      }
      context.dispatch(SalesActions.COMPUTE_TOTALS, null, { root: true })
      context.dispatch(DrawerActions.CLOSE, null, { root: true })
    },
    [Actions.SET_OMAGGIO]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.CLEAR_CORREZIONE_PERCENTUALE)
      context.commit(Mutations.CLEAR_IMPORTO_CORREZIONE)
      context.commit(Mutations.CLEAR_IMPORTO_FINALE)
      context.commit(Mutations.CLEAR_IMPORTO_CUSTOM)
      context.commit(Mutations.CLEAR_SARTORIE)
      context.dispatch(Actions.UPDATE_IMPORTO_FINALE)
    },
    [Actions.SET_RDV]: (
      context: ActionContext<ProductState, RootState>,
      rdv: GetCashiersAttributes,
    ): void => {
      context.commit(Mutations.STORE_RDV, rdv)
    },
    [Actions.SET_IMPORTO_TIPO]: (
      context: ActionContext<ProductState, RootState>,
      tipo: string,
    ): void => {
      context.commit(Mutations.STORE_IMPORTO_TIPO, tipo)
    },
    [Actions.SET_CODICE_MOVIMENTO]: (
      context: ActionContext<ProductState, RootState>,
      codice: string,
    ): void => {
      context.commit(Mutations.STORE_CODICE_MOVIMENTO, codice)
    },
    [Actions.SET_IVA]: (
      context: ActionContext<ProductState, RootState>,
      iva: number,
    ): void => {
      context.commit(Mutations.STORE_IVA, iva)
    },
    [Actions.SET_CORREZIONE_IMPORTO]: (
      context: ActionContext<ProductState, RootState>,
      importo: string,
    ): void => {
      const i = roundDecimals(parseFloat(importo))
      context.commit(Mutations.STORE_IMPORTO_CORREZIONE, i)
      context.commit(
        Mutations.STORE_IMPORTO_FINALE,
        roundDecimals(
          context.state.capo?.importo_iniziale
            ? context.state.capo?.importo_iniziale + i
            : (context.state.capo?.importo_iniziale as number),
        ),
      )
      const sconto =
        (100 * i) / (context.state.capo?.importo_iniziale as number)
      const c = sconto > 0 ? Math.floor(sconto) : Math.ceil(sconto)
      context.commit(Mutations.STORE_CORREZIONE_PERCENTUALE, c)
      context.commit(
        Mutations.STORE_IMPORTO_CUSTOM,
        context.state.capo?.importo_iniziale + i,
      )
      const correzione = c ? parseInt(c?.toString(), 10) : 0
      context.commit(Mutations.STORE_SCONTO, correzione)
    },
    [Actions.SET_CORREZIONE_PERCENTUALE]: (
      context: ActionContext<ProductState, RootState>,
      correzione: string,
    ): void => {
      const c = correzione ? parseInt(correzione, 10) : 0
      const d = roundDecimals(
        ((context.state.capo?.importo_iniziale as number) / 100) * c,
      )
      context.commit(Mutations.STORE_CORREZIONE_PERCENTUALE, c)
      context.commit(
        Mutations.STORE_IMPORTO_FINALE,
        roundDecimals(
          context.state.capo?.importo_iniziale
            ? context.state.capo?.importo_iniziale + d
            : (context.state.capo?.importo_iniziale as number),
        ),
      )
      context.commit(Mutations.STORE_IMPORTO_CORREZIONE, roundDecimals(d))
      context.commit(
        Mutations.STORE_IMPORTO_CUSTOM,
        context.state.capo?.importo_iniziale
          ? context.state.capo?.importo_iniziale + d
          : (context.state.capo?.importo_iniziale as number),
      )
      context.commit(Mutations.STORE_SCONTO, c)
    },
    [Actions.SET_IMPORTO_CUSTOM]: (
      context: ActionContext<ProductState, RootState>,
      importo: string,
    ): void => {
      const i = parseFloat(importo)
      context.commit(Mutations.STORE_IMPORTO_CUSTOM, roundDecimals(i))
      context.commit(Mutations.STORE_IMPORTO_FINALE, roundDecimals(i))
      context.commit(
        Mutations.STORE_IMPORTO_CORREZIONE,
        roundDecimals(
          -((context.state.product?.prezzo_iniziale as number) - i),
        ),
      )
      const sconto =
        -(100 * ((context.state.product?.prezzo_iniziale as number) - i)) /
        (context.state.capo?.importo_iniziale as number)
      const c = sconto > 0 ? Math.floor(sconto) : Math.ceil(sconto)

      context.commit(Mutations.STORE_CORREZIONE_PERCENTUALE, c)
      const correzione = c ? parseInt(c?.toString(), 10) : 0
      context.commit(Mutations.STORE_SCONTO, correzione)
    },
    [Actions.SET_IMPORTO_PRODOTTO_INIZIALE]: (
      context: ActionContext<ProductState, RootState>,
      importo: string,
    ): void => {
      const i = parseFloat(importo)
      context.commit(Mutations.STORE_IMPORTO_PRODOTTO_INIZIALE, i)
    },
    [Actions.FETCH_ALTERATIONS]: async (
      context: ActionContext<ProductState, RootState>,
    ): Promise<void> => {
      await itemsApi
        .apiV1PoswebAlterationsPricesGet(context.state.product?.classe)
        .then((r: AxiosResponse<GetItemsPriceAlteration>) => {
          context.commit(
            Mutations.STORE_ALTERATIONS,
            r.data.data?.map((a: GetItemsPriceAlterationData) => a.attributes),
          )
        })
    },
    [Actions.ADD_SARTORIA]: (
      context: ActionContext<ProductState, RootState>,
      sartoria:
        | ApiV1SalesDataAttributesPagamenti
        | ApiV1SalesSaleIdDataAttributesPagamenti,
    ): void => {
      if (
        !context.state.sartorie.find(
          (s) => s.cod_operazione === sartoria.cod_operazione,
        )
      ) {
        context.commit(Mutations.STORE_SARTORIE, [
          ...context.state.sartorie,
          sartoria,
        ])
      }
    },
    [Actions.REMOVE_SARTORIA]: (
      context: ActionContext<ProductState, RootState>,
      codice_operazione: string,
    ): void => {
      context.commit(
        Mutations.STORE_SARTORIE,
        context.state.sartorie.filter(
          (s) => s.cod_operazione !== codice_operazione,
        ),
      )
    },
    [Actions.SET_SARTORIA_PRICE]: (
      context: ActionContext<ProductState, RootState>,
      payload: { cod_operazione: string; price: number },
    ): void => {
      context.commit(
        Mutations.STORE_SARTORIE,
        context.state.sartorie.map((s) =>
          s.cod_operazione === payload.cod_operazione
            ? {
                ...s,
                codice_movimento: payload.price
                  ? TipiMovimenti.CONTABILITA_SARTORIA
                  : TipiMovimenti.CONTABILITA_SARTORIA_OMAGGIO,
                importo_finale: payload.price,
                importo_iniziale: payload.price,
              }
            : s,
        ),
      )
    },
    [Actions.RESET_SARTORIA_PRICE]: (
      context: ActionContext<ProductState, RootState>,
      cod_operazione: string,
    ): void => {
      context.commit(
        Mutations.STORE_SARTORIE,
        context.state.sartorie.map((s) =>
          s.cod_operazione === cod_operazione
            ? {
                ...s,
                importo_finale: 0,
              }
            : s,
        ),
      )
    },
    [Actions.SET_SARTORIA_DESCRIPTION]: (
      context: ActionContext<ProductState, RootState>,
      payload: { cod_operazione: string; descrizione: string },
    ): void => {
      context.commit(
        Mutations.STORE_SARTORIE,
        context.state.sartorie.map((s) =>
          s.cod_operazione === payload.cod_operazione
            ? {
                ...s,
                dati_operazione: JSON.stringify({
                  ...JSON.parse(s.dati_operazione as string),
                  descrizione: payload.descrizione,
                }),
              }
            : s,
        ),
      )
    },
    [Actions.SET_NOTA]: (
      context: ActionContext<ProductState, RootState>,
      note: string,
    ): void => {
      context.commit(Mutations.STORE_NOTE, note)
    },
    [Actions.RESET_NOTA]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.CLEAR_NOTE)
    },
    [Actions.SET_CONSEGNA]: (
      context: ActionContext<ProductState, RootState>,
      data: string,
    ): void => {
      context.commit(Mutations.STORE_CONSEGNA_DATA, data)
    },
    [Actions.RESET_CONSEGNA]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.CLEAR_CONSEGNA_DATA)
    },
    [Actions.UPDATE_IMPORTO_FINALE]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.STORE_IMPORTO_FINALE, 0)
    },
    [Actions.STORE_NAME]: (
      context: ActionContext<ProductState, RootState>,
      name: string,
    ): void => {
      context.commit(Mutations.STORE_NAME, name)
    },
    [Actions.STORE_CLASS]: (
      context: ActionContext<ProductState, RootState>,
      classe: string,
    ): void => {
      context.commit(Mutations.STORE_CLASS, classe)
      context.dispatch(Actions.FETCH_ALTERATIONS)
    },
    [Actions.STORE_COMPOSITION]: (
      context: ActionContext<ProductState, RootState>,
      inputComp: {
        lining: LocalComposizioneItem[]
        tissue: LocalComposizioneItem[]
      },
    ): void => {
      const comp: GetSkuDataAttributesComposizione = {
        T: [mapComposizione(inputComp.tissue)],
        F: [mapComposizione(inputComp.lining)],
      }
      if (comp?.T?.length && Object.keys(comp.T[0]).length === 0) {
        delete comp.T
      }
      if (comp?.F?.length && Object.keys(comp.F[0]).length === 0) {
        delete comp.F
      }
      context.commit(Mutations.STORE_COMPOSITION, comp)
    },
    [Actions.STORE_IMPORTO_INIZIALE]: (
      context: ActionContext<ProductState, RootState>,
      importo: number,
    ): void => {
      context.commit(Mutations.STORE_IMPORTO_INIZIALE, importo)
    },
    [Actions.CHANGE_TYPE]: (
      context: ActionContext<ProductState, RootState>,
    ): void => {
      context.commit(Mutations.CLEAR_SARTORIE)
      context.commit(Mutations.CLEAR_CORREZIONE_PERCENTUALE)
      context.commit(Mutations.CLEAR_IMPORTO_CORREZIONE)
      context.commit(Mutations.CLEAR_IMPORTO_CUSTOM)
      context.commit(Mutations.CLEAR_IMPORTO_FINALE)
    },
  },
  getters: {
    [Getters.GET_BARCODE]: (state: ProductState): string | null => {
      return state.barcode
    },
    [Getters.GET_PROGRESSIVO]: (state: ProductState): number | undefined => {
      return state.progressivo
    },
    [Getters.GET_CAPO]: (
      state: ProductState,
    ):
      | ApiV1SalesDataAttributesCapi
      | ApiV1SalesSaleIdDataAttributesCapi
      | undefined => {
      return state.capo
    },
    [Getters.GET_PRODUCT]: (
      state: ProductState,
    ): GetSkuDataAttributes | undefined => {
      return state.product
    },
    [Getters.GET_RDV]: (
      state: ProductState,
    ): GetCashiersAttributes | undefined => {
      return state.selectedRDV
    },
    [Getters.GET_CORREZIONE_PERCENTUALE]: (
      state: ProductState,
    ): number | undefined => {
      return state.correzione_percentuale
    },
    [Getters.GET_ALTERATIONS]: (
      state: ProductState,
    ): GetItemsPriceAlterationAttributes[] => {
      return state.alterations
    },
    [Getters.GET_SARTORIE]: (
      state: ProductState,
    ):
      | ApiV1SalesDataAttributesPagamenti[]
      | ApiV1SalesSaleIdDataAttributesPagamenti[] => {
      return state.sartorie
    },
    [Getters.GET_SARTORIE_PRICE]: (state: ProductState): number => {
      const sumSartorie: number = state.sartorie
        .map((s) => s.importo_finale ?? 0)
        .reduce((t: number, s: number) => {
          return t + s
        }, 0)
      const price = roundDecimals(
        state.capo?.importo_finale
          ? state.capo?.importo_finale + sumSartorie
          : (state.capo?.importo_finale as number),
      )
      if (state.freeReturn) {
        return Math.abs(price) * -1
      }
      return price
    },
    [Getters.GET_COMPOSIZIONE]: (
      state: ProductState,
    ): { T: LocalComposizioneItem[]; F: LocalComposizioneItem[] } => {
      let tissue: LocalComposizioneItem[] = []
      let lining: LocalComposizioneItem[] = []
      if (state.product?.composizione?.T?.length) {
        const obj = state.product.composizione.T[0]
        tissue = Object.keys(obj).map((k) => ({
          key: k,
          value: obj[k],
        }))
      }
      if (state.product?.composizione?.F?.length) {
        const obj = state.product.composizione.F[0]
        lining = Object.keys(obj).map((k) => ({
          key: k,
          value: obj[k],
        }))
      }
      return {
        T: Array.from([0, 1, 2, 3]).map(
          (i) => tissue[i] ?? { key: '', value: '' },
        ),
        F: Array.from([0, 1]).map((i) => lining[i] ?? { key: '', value: '' }),
      }
    },
    [Getters.GET_NOTA]: (state: ProductState) => {
      if (state.capo?.custom_data) {
        return { ...JSON.parse(state.capo.custom_data as string) }
          ?.nota_lavorazioni
      }
      return ''
    },
    [Getters.GET_DATA]: (state: ProductState) => {
      if (state.capo?.custom_data) {
        return { ...JSON.parse(state.capo.custom_data as string) }
          ?.data_consegna_sartoria
      }
      return ''
    },
  },
}
export default productStore

export const ProductActions = {
  RESET_STATE: `product/${Actions.RESET_STATE}`,
  EDIT_PRODUCT: `product/${Actions.EDIT_PRODUCT}`,
  UNDO_PRODUCT: `product/${Actions.UNDO_PRODUCT}`,
  SAVE_PRODUCT: `product/${Actions.SAVE_PRODUCT}`,
  SET_OMAGGIO: `product/${Actions.SET_OMAGGIO}`,
  SET_RDV: `product/${Actions.SET_RDV}`,
  SET_IMPORTO_TIPO: `product/${Actions.SET_IMPORTO_TIPO}`,
  SET_IMPORTO_CUSTOM: `product/${Actions.SET_IMPORTO_CUSTOM}`,
  SET_IMPORTO_PRODOTTO_INIZIALE: `product/${Actions.SET_IMPORTO_PRODOTTO_INIZIALE}`,
  SET_CODICE_MOVIMENTO: `product/${Actions.SET_CODICE_MOVIMENTO}`,
  SET_CORREZIONE_IMPORTO: `product/${Actions.SET_CORREZIONE_IMPORTO}`,
  SET_CORREZIONE_PERCENTUALE: `product/${Actions.SET_CORREZIONE_PERCENTUALE}`,
  FETCH_ALTERATIONS: `product/${Actions.FETCH_ALTERATIONS}`,
  ADD_SARTORIA: `product/${Actions.ADD_SARTORIA}`,
  REMOVE_SARTORIA: `product/${Actions.REMOVE_SARTORIA}`,
  SET_SARTORIA_PRICE: `product/${Actions.SET_SARTORIA_PRICE}`,
  RESET_SARTORIA_PRICE: `product/${Actions.RESET_SARTORIA_PRICE}`,
  SET_SARTORIA_DESCRIPTION: `product/${Actions.SET_SARTORIA_DESCRIPTION}`,
  SET_NOTA: `product/${Actions.SET_NOTA}`,
  RESET_NOTA: `product/${Actions.RESET_NOTA}`,
  SET_CONSEGNA: `product/${Actions.SET_CONSEGNA}`,
  RESET_CONSEGNA: `product/${Actions.RESET_CONSEGNA}`,
  UPDATE_IMPORTO_FINALE: `product/${Actions.UPDATE_IMPORTO_FINALE}`,
  STORE_NAME: `product/${Actions.STORE_NAME}`,
  STORE_CLASS: `product/${Actions.STORE_CLASS}`,
  STORE_COMPOSITION: `product/${Actions.STORE_COMPOSITION}`,
  STORE_IMPORTO_INIZIALE: `product/${Actions.STORE_IMPORTO_INIZIALE}`,
  SET_BARCODE: `product/${Actions.SET_BARCODE}`,
  DELETE_BARCODE: `product/${Actions.DELETE_BARCODE}`,
  CHANGE_TYPE: `product/${Actions.CHANGE_TYPE}`,
}
export const ProductMutations = {
  STORE_PROGRESSIVO: `product/${Mutations.STORE_PROGRESSIVO}`,
  CLEAR_PROGRESSIVO: `product/${Mutations.CLEAR_PROGRESSIVO}`,
  STORE_CAPO: `product/${Mutations.STORE_CAPO}`,
  CLEAR_CAPO: `product/${Mutations.CLEAR_CAPO}`,
  STORE_PRODUCT: `product/${Mutations.STORE_PRODUCT}`,
  CLEAR_PRODUCT: `product/${Mutations.CLEAR_PRODUCT}`,
  STORE_RDV: `product/${Mutations.STORE_RDV}`,
  CLEAR_RDV: `product/${Mutations.CLEAR_RDV}`,
  STORE_IMPORTO_FINALE: `product/${Mutations.STORE_IMPORTO_FINALE}`,
  CLEAR_IMPORTO_FINALE: `product/${Mutations.CLEAR_IMPORTO_FINALE}`,
  STORE_IMPORTO_CUSTOM: `product/${Mutations.STORE_IMPORTO_CUSTOM}`,
  CLEAR_IMPORTO_CUSTOM: `product/${Mutations.CLEAR_IMPORTO_CUSTOM}`,
  STORE_IMPORTO_CORREZIONE: `product/${Mutations.STORE_IMPORTO_CORREZIONE}`,
  CLEAR_IMPORTO_CORREZIONE: `product/${Mutations.CLEAR_IMPORTO_CORREZIONE}`,
  STORE_IMPORTO_TIPO: `product/${Mutations.STORE_IMPORTO_TIPO}`,
  CLEAR_IMPORTO_TIPO: `product/${Mutations.CLEAR_IMPORTO_TIPO}`,
  STORE_IVA: `product/${Mutations.STORE_IVA}`,
  CLEAR_IVA: `product/${Mutations.CLEAR_IVA}`,
  STORE_NAME: `product/${Actions.STORE_NAME}`,
  STORE_CLASS: `product/${Actions.STORE_CLASS}`,
  STORE_COMPOSITION: `product/${Mutations.STORE_COMPOSITION}`,
  DELETE_BARCODE: `product/${Mutations.DELETE_BARCODE}`,
}
export const ProductGetters = {
  GET_PROGRESSIVO: `product/${Getters.GET_PROGRESSIVO}`,
  GET_CAPO: `product/${Getters.GET_CAPO}`,
  GET_PRODUCT: `product/${Getters.GET_PRODUCT}`,
  GET_RDV: `product/${Getters.GET_RDV}`,
  GET_CORREZIONE_PERCENTUALE: `product/${Getters.GET_CORREZIONE_PERCENTUALE}`,
  GET_ALTERATIONS: `product/${Getters.GET_ALTERATIONS}`,
  GET_SARTORIE: `product/${Getters.GET_SARTORIE}`,
  GET_SARTORIE_PRICE: `product/${Getters.GET_SARTORIE_PRICE}`,
  GET_COMPOSIZIONE: `product/${Getters.GET_COMPOSIZIONE}`,
  GET_NOTA: `product/${Getters.GET_NOTA}`,
  GET_DATA: `product/${Getters.GET_DATA}`,
  GET_BARCODE: `product/${Getters.GET_BARCODE}`,
}
