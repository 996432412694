// store/mutations.ts
import { MutationTree } from 'vuex'
import { State } from './_state'
import { notificationGroupData } from '@/components/notifications-list/notifications'

export enum MutationNames {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
}

export const mutations: MutationTree<State> = {
  /**
   * Adds a new notification to the notifications array.
   * @param {State} state - The Vuex state.
   * @param {notificationGroupData} notification - The notification data to add.
   */
  [MutationNames.SET_NOTIFICATION](
    state,
    notifications: notificationGroupData[],
  ) {
    state.notifications = notifications
  },
}
