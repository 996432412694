import { OMAuthenticationHelper } from '@/addons/authentication'
import { ConnectivityChecker } from '@/addons/connectivity'
import { LocalStorageKeys, SessionKeys } from '@/addons/enums'
import { isNativeOrWebIos } from '@/addons/mobile'
import { FeLogger, initAndStartAPM } from '@/addons/monitoring'
import store from '@/store'
import { AuthActions } from '@/store/auth'
import { ConfigActions, ConfigGetters } from '@/store/configs-store'
import { GenericActions } from '@/store/generic-store'
import { MenuActions, MenuGetters } from '@/store/menu-store'
import { NotificationsActions } from '@/store/notifications-store'
import { PaymentsActions } from '@/store/payments-store'
import { SalesActions, SalesGetters } from '@/store/sales/sales-store'
import { RouteLocationNormalized } from 'vue-router'
import { DispatchOptions } from 'vuex'
import router, { __BYPASS_AUTH_GUARD } from '.'
import { Routes } from './routes'
import { ConsumersActions } from '@/store/consumer/index'

/**
 * An authentication guard to check whether current user is logged in or not.
 * If during these checks something breaks, we will redirect user to the login page.
 */
export async function authenticationGuard() {
  try {
    // Before going on, we have to wait for the response of this action.
    await store.dispatch(ConfigActions.FETCH_STORE_CONFIGS)

    // Try to verify the auth status. If user is authenticated, he/she can go on.
    await store.dispatch(
      AuthActions.UPDATE_AUTH_STATUS,
      store.state.generic.currentLanguage,
    )

    // Since we reached this point, we can load the requested page.
    return true
  } catch (error) {
    FeLogger.error(`Something went wrong in the authentication guard`, error)

    // If we caught an exception, perform the logout process.
    // This will also redirect user to the login page.
    await store.dispatch(AuthActions.LOGOUT, null, { root: true })

    // Since we reached this point, we cannot go ahead.
    return false
  }
}

/**
 * An authorization guard to determine whether current user can access the request page.
 * If so, we will let him/her through. Otherwise, user will be redirected to the login
 * page and the navigation to the requested page gets cancelled.
 * @param to Destination route
 */
export async function authorizationGuard(to: RouteLocationNormalized) {
  // If requested page is unprivileged, hence accessible by anyone, we can short circuit out.
  if (to.meta.isUnprivileged) {
    return true
  }

  // If we have the special flag to bypass the authorization guard and we are not in a production
  // environment, we can let user in. We will also log a warning to inform devs about this mock.
  if (
    to.meta[__BYPASS_AUTH_GUARD] &&
    !store.getters[ConfigGetters.IS_LIVE_ENVIRONMENT]
  ) {
    FeLogger.warn(
      `Allowing navigation to page identified by the following path ${to.fullPath}. This route will not be accessible in production!`,
    )

    return true
  }

  const canAccessPage = store.getters[MenuGetters.HAS_ITEM_WITH_PATH]

  // If we can consider this page as logically equal to other pages, at least from an authorization standpoint,
  // we have to check whether one of those pages can be accessed by current user.
  if (to.meta.sameAccessRulesAs?.some((path) => canAccessPage(path))) {
    return true
  }

  // For older pages, we have to take in consideration the whole path. For newer ones, we can ignore query strings.
  const path = to.fullPath.startsWith(Routes.PREVIOUS_UI_PAGES)
    ? to.fullPath
    : to.path

  // If we reached this point, we have to validate current page against pages we
  // already know and have saved in state store. If user can access, let him/her in.
  if (canAccessPage(path)) {
    return true
  }

  // Since we reached this point, user is not authorized. Redirect him/her to the dashboard.
  await router.replace(Routes.DASHBOARD)
  return false
}

import { useGenericStore } from '@/stores/GenericStore'
export async function globalDataService() {
  const options: DispatchOptions = { root: true }

  try {
    // Start the connectivity checks.
    ConnectivityChecker.getInstance().start()

    // Before going on, check whether we have setup data. If not, we have to fetch those global configs.
    if (!store.getters[ConfigGetters.GET_STORE_SETUP]) {
      await store.dispatch(ConfigActions.FETCH_STORE_SETUP)
    }

    await Promise.all([
      store.dispatch('cashiers/fetchCashiers', null, options),
      store.dispatch(ConsumersActions.FETCH_ANONYMOUS_CONSUMERS, null, options),
      store.dispatch(GenericActions.FETCH_NATIONS, null, options),
      store.dispatch(GenericActions.SET_CURRENCY, null, options),
      store.dispatch(GenericActions.FETCH_VAT_CODES, null, options),
      store.dispatch(ConfigActions.UPDATE_DISCOUNTS, null, options),
      store.dispatch(ConfigActions.UPDATE_PRODUCT_CLASSES, null, options),
      store.dispatch(ConfigActions.SET_FABRICS, null, options),
      store.dispatch(PaymentsActions.GET_PAYMENTS_TYPES, null, options),
      store.dispatch(PaymentsActions.GET_DOCUMENTS_TYPES, null, options),
      store.dispatch(AuthActions.FETCH_CASHES, null, options),
      store.dispatch(MenuActions.UPDATE, null, options),
      useGenericStore().fetchNations(),
      useGenericStore().setCurrency(),
      useGenericStore().fetchVatCodes(),
    ])

    // If we are being executed on an iPad and we have stored the id of a previously selected cash, we can selected
    // it once again. We have to do this operation only if the application has been reopened after being closed
    // and disposed. This allows us to keep working on the same cash we selected during the login process, even
    // across page reloads and app relaunches. To prevent double checks after the login process on iPad(s), we
    // perform these steps if and only if the previously saved selected cash id is different
    // from the currently selected one.
    const savedSelectedCashId = localStorage.getItem(
      LocalStorageKeys.SELECTED_CASH,
    )
    const currentlySelectedCashId = store.getters[ConfigGetters.SELECTED_CASH]

    if (
      isNativeOrWebIos() &&
      typeof savedSelectedCashId !== 'undefined' &&
      savedSelectedCashId !== currentlySelectedCashId
    ) {
      await store.dispatch(
        AuthActions.SELECT_CASH,
        savedSelectedCashId,
        options,
      )
    }
  } catch (error) {
    FeLogger.error(`Something went wrong while fetching common data`, error)

    store.dispatch(
      NotificationsActions.NOTIFY_ERROR,
      'common.global_config_error',
      options,
    )

    await store.dispatch(AuthActions.LOGOUT, null, options)

    return false
  }

  try {
    OMAuthenticationHelper.getInstance().start()

    // We enable DataDog only if we are not in development.
    if (!import.meta.env.DEV) {
      await initAndStartAPM()
    }
  } catch (error) {
    FeLogger.error(
      `Something went wrong while bootstrapping OM and Datadog. Trying to go ahead...`,
      error,
    )
  }

  return true
}
export async function salesDataService() {
  const currentSale = store.getters[SalesGetters.GET_CURRENT_SALE]

  // We consider a sale to be logically empty either when we have no data about it or,
  // when we have some data, every of those records is `undefined`.
  const isEmptySale =
    typeof currentSale === 'undefined' ||
    Object.values(currentSale).every((value) => typeof value === 'undefined')

  // If we have an empty sale, we can init one and eventually hydrate it.
  // Otherwise we can keep what we already have in store.
  if (isEmptySale) {
    const savedSale = JSON.parse(
      sessionStorage.getItem(SessionKeys.CURRENT_SALE) || '{}',
    )

    await store.dispatch(SalesActions.INIT_SALE, null, { root: true })
    // If we have saved a sale, we have to hydrate it
    if (savedSale) {
      await store.dispatch(SalesActions.HYDRATE_SALES, savedSale, {
        root: true,
      })
      if (savedSale?.pk_consumer) {
        await store.dispatch(
          ConsumersActions.SET_CURRENT_CONSUMER,
          savedSale?.pk_consumer,
        )
      }
    }
  }

  return true
}

export async function beforeEnter(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
) {
  if (to.redirectedFrom && to.redirectedFrom.fullPath !== '/') {
    const message = `Detected a fallback frontend redirect. This might be caused by a missing menu item. Tried to load route "${from.fullPath}", redirected to "${to.fullPath}"`

    FeLogger.warn(message)
  }

  const canProceed = await authenticationGuard()

  // If cannot proceed, make sure to return a `false` so that we do not trigger other guards.
  if (!canProceed) {
    // Since we are in a inconsistent state and we would not be able to go ahead, reload the page.
    // This will ensure that we are working with the latest available data.
    window.location.reload()
    return false
  }

  return globalDataService()
}

/*
export function setContext(mainCtx: PageContexts): PageContexts {
  const customDate = sessionStorage.getItem(SessionKeys.CUSTOM_DATE)
  if (customDate) {
    return PageContexts.CUSTOM_DATE
  }
  return mainCtx
}

export function setRoutes(
  routes: Array<RouteRecordRaw>
): Array<RouteRecordRaw> {
  routes.map((r) => {
    if (r?.children?.length) {
      r?.children.map((c) => {
        if (c?.meta) {
          c.meta.context = setContext(c?.meta?.context)
        }
        return c
      })
    }
    return r
  })
  return routes
}

 */
