import { storeManagementApi, systemApi } from '@/addons/axios'
import { DrawerName, SessionKeys } from '@/addons/enums'
import {
  default as i18n,
  default as i18nInstance,
  numberFormats,
} from '@/addons/i18n'
import {
  GetCountriesAttributes,
  GetStoreConfigDataAttributes,
  GetTranslations,
  GetVATRateDataAttributes,
} from '@/api'
import { AuthActions } from '@/store/auth'
import { DrawerActions } from '@/store/drawer-store'
import { ActionContext } from 'vuex'
import store, { RootState } from '.'
import { ConfigGetters } from './configs-store'

enum Mutations {
  RESET_STATE = 'reset-state',
  STORE_NATIONS = 'storeNations',
  STORE_VAT_CODES = 'storeVatCodes',
  STORE_NATIONS_TRANSLATIONS = 'storeNationsTranslations',
  STORE_CURRENT_LANGUAGE = 'storeCurrentLanguage',
}

enum Getters {
  GET_CURRENT_LANGUAGE = 'getCurrentLanguage',
  GET_NATIONS = 'getNations',
  GET_NATIONS_TRANSLATIONS = 'getNationsTranslations',
  GET_VAT_CODES = 'getVatCodes',
}

enum Actions {
  RESET_STATE = 'reset-state',
  FETCH_NATIONS = 'fetchNations',
  SET_CURRENT_LANGUAGE = 'setCurrentLanguage',
  FETCH_VAT_CODES = 'fetchVatCodes',
  SET_CURRENCY = 'setCurrency',
  OPEN_PAYMENTS = 'openPayments',
}

export interface GenericState {
  currentLanguage: string
  nations: GetCountriesAttributes[]
  nationsTranslations: GetTranslations[]
  vatCodes: GetVATRateDataAttributes
  setLanguageToDefault: boolean
}

const initState = (): GenericState => ({
  nations: [],
  currentLanguage: i18nInstance.global.locale,
  nationsTranslations: [],
  vatCodes: {},
  setLanguageToDefault: !sessionStorage.getItem(SessionKeys.LOCALE), // whether language should be assigned from /global_config call
})

const genericStore = {
  namespaced: true,
  state: initState,
  mutations: {
    [Mutations.RESET_STATE]: (state: GenericState): void => {
      Object.assign(state, initState())
    },
    [Mutations.STORE_NATIONS]: (
      state: GenericState,
      nations: GetCountriesAttributes[],
    ): void => {
      state.nations = nations
    },
    [Mutations.STORE_VAT_CODES]: (
      state: GenericState,
      vatCodes: GetVATRateDataAttributes,
    ): void => {
      state.vatCodes = vatCodes
    },
    [Mutations.STORE_NATIONS_TRANSLATIONS]: (
      state: GenericState,
      nationsTranslations: GetTranslations[],
    ): void => {
      state.nationsTranslations = nationsTranslations
    },
    [Mutations.STORE_CURRENT_LANGUAGE]: (
      state: GenericState,
      currentLanguage: string,
    ): void => {
      state.currentLanguage = currentLanguage
    },
  },
  actions: {
    [Actions.RESET_STATE]: (
      context: ActionContext<GenericState, RootState>,
    ): void => {
      context.commit(Mutations.RESET_STATE)
    },
    async [Actions.FETCH_NATIONS](
      context: ActionContext<GenericState, RootState>,
    ): Promise<void> {
      const countries = await systemApi.apiV1PoswebCountriesGet()
      const translations = await systemApi.apiV1PoswebTranslationsGet(
        context.getters.getCurrentLanguage,
        'country_code',
      )

      context.commit(
        Mutations.STORE_NATIONS,
        countries.data.data?.map((r) => r.attributes),
      )
      context.commit(
        Mutations.STORE_NATIONS_TRANSLATIONS,
        translations.data.data,
      )
    },
    async [Actions.SET_CURRENT_LANGUAGE](
      context: ActionContext<GenericState, RootState>,
      language: string,
    ): Promise<void> {
      context.commit(Mutations.STORE_CURRENT_LANGUAGE, language)
      await context.dispatch(AuthActions.UPDATE_AUTH_STATUS, language, {
        root: true,
      })
    },
    async [Actions.FETCH_VAT_CODES](
      context: ActionContext<GenericState, RootState>,
    ): Promise<void> {
      const configs = store.getters[ConfigGetters.GET_STORE_CONFIGS] as
        | GetStoreConfigDataAttributes
        | undefined

      if (configs?.COUNTRY_CODE) {
        const response = await storeManagementApi.apiV1PoswebVatCodesGet(
          configs?.COUNTRY_CODE,
          '',
          '',
          '',
        )

        context.commit(
          Mutations.STORE_VAT_CODES,
          response.data.data?.attributes,
        )
      }
    },
    [Actions.SET_CURRENCY]: (
      context: ActionContext<GenericState, RootState>,
    ): void => {
      const storeConfig = context.rootState.configs.currentStore
      i18n.global.setNumberFormat('en', {
        currency: numberFormats.currency(
          storeConfig?.CURRENCY_CODE,
          storeConfig?.CURRENCY_DECIMAL,
        ),
      })
    },
    [Actions.OPEN_PAYMENTS]: (
      context: ActionContext<GenericState, RootState>,
    ): void => {
      context.dispatch(
        DrawerActions.OPEN,
        {
          component: DrawerName.DRAWER_PAYMENTS,
          title: i18n.global.t('pos_sale.payments'),
        },
        { root: true },
      )
    },
  },
  getters: {
    [Getters.GET_CURRENT_LANGUAGE]: (state: GenericState): string =>
      state.currentLanguage,
    [Getters.GET_NATIONS]: (state: GenericState): GetCountriesAttributes[] =>
      state.nations,
    [Getters.GET_NATIONS_TRANSLATIONS]: (state: GenericState) =>
      state.nationsTranslations,
    [Getters.GET_VAT_CODES]: (state: GenericState): GetVATRateDataAttributes =>
      state.vatCodes,
  },
}

export default genericStore

export const GenericGetters = {
  GET_CURRENT_LANGUAGE: `generic/${Getters.GET_CURRENT_LANGUAGE}`,
  GET_NATIONS: `generic/${Getters.GET_NATIONS}`,
  GET_NATIONS_TRANSLATIONS: `generic/${Getters.GET_NATIONS_TRANSLATIONS}`,
  GET_VAT_CODES: `generic/${Getters.GET_VAT_CODES}`,
}

export const GenericActions = {
  RESET_STATE: `generic/${Actions.RESET_STATE}`,
  FETCH_NATIONS: `generic/${Actions.FETCH_NATIONS}`,
  SET_CURRENT_LANGUAGE: `generic/${Actions.SET_CURRENT_LANGUAGE}`,
  FETCH_VAT_CODES: `generic/${Actions.FETCH_VAT_CODES}`,
  SET_CURRENCY: `generic/${Actions.SET_CURRENCY}`,
  OPEN_PAYMENTS: `generic/${Actions.OPEN_PAYMENTS}`,
}
