<script setup lang="ts">
import { computed, useAttrs } from 'vue'
import { IonToggle, CheckboxCustomEvent } from '@ionic/vue'

//  name: 'FeToggle',

const props = defineProps<{
  modelValue: boolean
  label?: string
  disabled?: boolean
  reverse?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
  (e: 'change', value: boolean): void
}>()

const labelPlacement = computed(() => {
  if (!props.label) {
    return undefined
  }
  return props.reverse ? 'start' : 'end'
})

function handleToggleChange(event: CheckboxCustomEvent): void {
  const eventTarget = event.target as unknown as HTMLIonToggleElement
  change(eventTarget.checked)
}

function change(value: boolean): void {
  emit('change', value)
  emit('update:modelValue', value)
}
/**
 * Retrieves all dynamic attributes that are not defined as props for the component.
 * This includes attributes like `placeholder`, `data-test`, etc. that are passed to the component.
 * These attributes are then bound to the `ion-input` element within the component.
 * Like the old `inheritAttrs: false`
 */
const attrs = useAttrs()
</script>

<template>
  <div>
    <ion-toggle
      ref="absToggle"
      v-bind="attrs"
      :label-placement="labelPlacement"
      :checked="modelValue"
      :disabled="disabled"
      @ion-change="handleToggleChange"
    >
      {{ label }}
    </ion-toggle>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
/*
ion-item {
  --inner-border-width: 0;
  border: none;
  display: flex;
  align-items: center;
  height: 3.5rem;
  margin-bottom: 0;
  flex-direction: row-reverse;
  max-width: 24rem;
}
*/
/**/
ion-toggle {
  --background: #{$color-support-grey};
  --background-checked: #{$color-selected-bg};
  --handle-background: #{$color-base};
  --handle-background-checked: #{$color-selected-light};
  --handle-width: 2rem;
  --handle-height: 2rem;
  --handle-max-height: 2rem;
  --handle-spacing: 0;
  --padding-inline: 0;
  --track-height: 0.4rem;
  padding-left: 0;
  padding-right: 0;
  // width: 3.6rem;
  min-width: 3.6rem;
  //max-width: 13.6rem;
  //  height: 0.4rem;
  // These properties fix an issue that resulted in toggles without the
  // "handle" part. Required only by WebKit.
  contain: none;
  overflow: visible;
  font-size: 1.35rem;
  // font-size: 1.6rem;
  &::part(track) {
    height: 0.4rem;
  }
}
/**/

/*
ion-label {
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  flex: 0;
  flex-basis: auto;

  .toggle-checked + & {
    --color: #{$color-selected-light};
  }
}
*/
</style>
