import { datadogConfigs } from '@/configs'
import store from '@/store'
import { ConfigGetters } from '@/store/configs-store'
import { Context } from '@datadog/browser-core'
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs'
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'
import { getSoftwareRevision } from './functions'

export async function initAndStartAPM() {
  const env = store.getters[ConfigGetters.DEPLOY_ENV]
  const cashCode = store.getters[ConfigGetters.SELECTED_CASH]
  const storeCode = store.getters[ConfigGetters.STORE_CODE]

  const isProd = env.toLowerCase() === 'live'
  const version = await getSoftwareRevision()

  const configs: RumInitConfiguration & LogsInitConfiguration = {
    ...datadogConfigs,
    env: isProd ? 'production' : 'testing',
    version: version,
  }

  const context: Context = {
    'cash.code': cashCode?.toString(),
    'store.code': storeCode?.toString(),
  }

  datadogRum.init(configs)
  datadogRum.setRumGlobalContext(context)

  datadogLogs.init(configs)
  datadogLogs.setGlobalContext(context)

  datadogRum.startSessionReplayRecording()
}

export abstract class FeLogger {
  private static logger: typeof datadogLogs.logger

  static init() {
    FeLogger.logger = datadogLogs.logger
  }

  static info(message: unknown, ...params: unknown[]) {
    // eslint-disable-next-line no-console
    console.info(message, ...params)
  }

  static warn(message: unknown, ...params: unknown[]) {
    FeLogger.logger.warn(
      typeof message !== 'string' ? JSON.stringify(message) : message,
    )

    // eslint-disable-next-line no-console
    console.warn(message, ...params)
  }

  static error(message: unknown, ...params: unknown[]) {
    FeLogger.logger.error(
      typeof message !== 'string' ? JSON.stringify(message) : message,
    )

    // eslint-disable-next-line no-console
    console.error(message, ...params)
  }
}
