<script lang="ts" setup>
import { formatCurrency } from '@/addons/currency'
import getImageUrl from '@/addons/images'
import { GetSkuDataAttributes } from '@/api'
import { defineProps, PropType } from 'vue'
import { IonImg, IonButton } from '@ionic/vue'
import FeIcon from '@/components/global/icon.vue'

const props = defineProps({
  product: {
    type: Object as PropType<GetSkuDataAttributes>,
    required: true,
  },
  codCashier: {
    type: String,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  mode: {
    type: String,
    required: true,
  },
})

const emits = defineEmits(['delete-product'])
props
</script>

<template>
  <div class="product-container">
    <div data-test="product-card" class="barcode-product">
      <div class="barcode-product-header">
        <p class="barcode-product-header-text">{{ index }}.</p>
      </div>
      <div class="barcode-product-overlay">
        <ion-img
          :src="getImageUrl(product, 'model')"
          class="barcode-product-img"
        />
        <div class="barcode-product-content">
          <div class="barcode-product-content-header">
            <div class="barcode-product-content-row">
              <p class="text-primary font-weight-primary">
                {{ product?.nome || '---' }}
              </p>
              <p class="text-secondary font-weight-tertiary">
                {{ product?.sku || '---' }}
              </p>
            </div>
            <div class="barcode-product-content-row">
              <p class="text-secondary font-weight-secondary">
                {{ product?.variante || '---' }}
                {{ product?.desc_colore || '' }}
              </p>
              <p class="text-primary font-weight-secondary">
                {{ product?.desc_taglia || '---' }}
              </p>
            </div>
            <div class="barcode-product-content-row">
              <p class="text-secondary font-weight-tertiary">
                {{ $t('pos_barcode.sales_assistant') }}
              </p>
              <p class="text-secondary font-weight-tertiary">
                {{ codCashier || '---' }}
              </p>
            </div>
          </div>
          <hr class="barcode-product-bar" />
          <div class="barcode-product-content-inner">
            <div class="barcode-product-content-row">
              <p class="text-primary font-weight-tertiary">
                {{ $t('pos_sale.price_list') }}
              </p>
              <p class="text-primary font-weight-tertiary">
                {{ formatCurrency(product?.prezzo_iniziale) }}
              </p>
            </div>
            <div class="barcode-product-content-row">
              <p class="text-primary font-weight-primary">
                {{ $t('pos_common.final_price') }}
              </p>
              <p class="text-primary font-weight-primary">
                {{ formatCurrency(product?.importo_finale) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="mode === 'multiple'" class="barcode-product-delete">
      <ion-button
        data-test="product-card-delete-button"
        @click="emits('delete-product', product)"
      >
        <div class="button-inner">
          <span class="card__row__delete__span">
            {{ $t('pos_common.delete') }}
          </span>
          <fe-icon icon="trash"></fe-icon>
        </div>
      </ion-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.product-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.barcode-product-overlay {
  position: relative;
  width: 100%;
  height: 92%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.barcode-product {
  background: #ffffff;
  width: 283px;
  height: 375px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &-header {
    font-size: 16px;
    font-weight: bold;
    padding: 0px 11px;

    &-text {
      margin: 4px 0 4px 0;
    }
  }

  &-bar {
    height: 1px;
    background-color: #c99483;
    margin: 15px 0;
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-content {
    width: 100%;
    background: rgba(255, 255, 255, 0.93);
    position: absolute;
    padding: 15px 11px;
    bottom: 0;
    left: 0;
    max-height: 600px;
    gap: 10px;

    &-header {
      line-height: 18.56px;
      /* 18.56px */
      letter-spacing: 0.48px;
      display: flex;
      flex-direction: column;
      gap: 9px;
    }

    &-inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 61px;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      margin: 0px 0px 4px 0px;

      p {
        margin: 0;
      }

      .text-primary {
        font-size: 16px;
      }

      .text-secondary {
        font-size: 14px;
      }

      .font-weight {
        &-primary {
          font-weight: 600;
        }

        &-secondary {
          font-weight: 500;
        }

        &-tertiary {
          font-weight: 400;
        }
      }
    }
  }
}

ion-button {
  width: 283px;
  --border-radius: 0;
  --color: #f44336;
  --background: #ffffff;
  --background-hover: #ffffff;
  --background-activated: #ffffff;
  --background-focused: #ffffff;

  .button-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
</style>
